import { addAuthorizationHeader, apiClientForm } from '@/services/api'

/**
 * File Upload API Endpoints
 *
 * Used by, but not to be confused with, the file upload activity type. These
 * handle any file uploads in general.
 */
export default {
  /**
   * Upload Media Activity File
   *
   * The initial file for media activities.
   *
   * @param {UUIDv7} activityUuid
   * @param {File[]} files -- Will always be an array of 1.
   * @returns {*}
   * @type {FileUploadEndpoint}
   */
  addFileToMediaActivity (activityUuid, files) {
    return addAuthorizationHeader(apiClientForm).post(
      '/api/activity/media/' + activityUuid + '/file',
      files,
    )
  },

  /**
   * Upload Video Activity File
   *
   * The video for video activities.
   *
   * @param {UUIDv7} activityUuid
   * @param {File[]} files -- Will always be an array of 1.
   * @returns {*}
   * @type {FileUploadEndpoint}
   */
  addFileToVideoActivity (activityUuid, files) {
    return addAuthorizationHeader(apiClientForm).post(
      '/api/activity/video/' + activityUuid + '/file',
      files,
    )
  },

  /**
   * Upload File Upload Activity File
   *
   * The learner-provided files for file upload activities.
   *
   * @param {UUIDv7} activityUuid
   * @param {File[]} files
   * @returns {*}
   * @type {FileUploadEndpoint}
   */
  addFileToFileUploadActivity (activityUuid, files) {
    return addAuthorizationHeader(apiClientForm).post(
      '/api/activity/file-upload/' + activityUuid + '/file',
      files,
    )
  },
}
