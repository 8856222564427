import api from '@/services/api.js'
import {
  SET_LEARNING_PATH,
  SET_CURRENT_LEARNING_PATH_UUID,
  SET_SHOW_LEARNING_PATH_NAVIGATION,
  SET_LEARNING_PATH_MAP_REVERSION_CONNECTIONS,
  SET_LEARNING_PATH_MAP_CONNECTIONS,
  SET_CURRENT_LEARNING_PATH_MAP,
  ADD_PAGE,
  UPDATE_PAGE,
  UPSERT_TO_ACTIVITY_CACHE,
} from '@/store/mutation-types.js'

export const learningPath = {
  state: {
    learningPath: undefined,
    currentLearningPathUuid: undefined,
    showLearningPathNavigation: localStorage.getItem('showLearningPathNavigation') || true,
    reversionConnections: undefined,

    /** @type {Activity[]} */
    activityCache: [],
  },

  mutations: {
    [SET_LEARNING_PATH_MAP_CONNECTIONS] (state, connections) {
      state.learningPath.map.connections = connections
    },

    [SET_LEARNING_PATH] (state, learningPath) {
      state.learningPath = learningPath

      if (typeof learningPath?.map?.connections !== 'undefined') {
        state.learningPath.map.connections = learningPath.map.connections
      }
    },

    [SET_CURRENT_LEARNING_PATH_UUID] (state, learningPathUuid) {
      state.currentLearningPathUuid = learningPathUuid
    },

    [SET_CURRENT_LEARNING_PATH_MAP] (state, map) {
      state.learningPath.map = map
    },

    [SET_SHOW_LEARNING_PATH_NAVIGATION] (state, showLearningPathNavigation) {
      state.showLearningPathNavigation = showLearningPathNavigation
    },

    [SET_LEARNING_PATH_MAP_REVERSION_CONNECTIONS] (state, reversionConnections) {
      state.reversionConnections = reversionConnections
    },

    /**
     * @param state
     * @param {Page} newPage
     */
    [ADD_PAGE] (state, newPage) {
      const module = state.learningPath.modules.find(
        aModule => aModule.id === newPage.module,
      )

      module.pages.push({
        activities: [], // Designer-only field.
        ...newPage,
      })
    },

    [UPDATE_PAGE] (state, changedPageFieldsAndId) {
      const module = state.learningPath.modules.find(
        aModule => aModule.id === changedPageFieldsAndId.module,
      )

      const page = module.pages.find(page => page.id === changedPageFieldsAndId.id)

      delete changedPageFieldsAndId.id
      delete changedPageFieldsAndId.module

      Object.assign(page, changedPageFieldsAndId)
    },

    [UPSERT_TO_ACTIVITY_CACHE] (state, activity) {
      // Remove it from the cache if it's already in there.
      const indexOfActivityAlreadyInCache = state.activityCache.findIndex(
        aCachedActivity => aCachedActivity.uuid === activity.uuid,
      )

      if (indexOfActivityAlreadyInCache !== undefined) {
        state.activityCache = state.activityCache.splice(
          indexOfActivityAlreadyInCache,
          1,
        )
      }

      // Add it to cache.
      state.activityCache.push(activity)
    },
  },

  actions: {
    async getLearningPath (
      { commit, state },
      { learningPathUuid, currentClientUuid },
    ) {
      if (
        state.learningPath !== undefined &&
        state.learningPath.uuid === learningPathUuid
      ) {
        return state.learningPath
      }

      commit(SET_LEARNING_PATH, undefined)
      commit(SET_CURRENT_LEARNING_PATH_UUID, undefined)

      const resp = await api.getLearningPath(learningPathUuid)
      const learningPath = resp.data.data.learningPath

      if (learningPath.client === currentClientUuid) {
        commit(SET_LEARNING_PATH, learningPath)
        commit(SET_CURRENT_LEARNING_PATH_UUID, learningPath.uuid)

        if (typeof learningPath?.map?.connections !== 'undefined') {
          commit(
            SET_LEARNING_PATH_MAP_REVERSION_CONNECTIONS,
            [...learningPath.map.connections],
          )
        }
      } else {
        alert('Learning path client does not match current client.')
      }

      return learningPath
    },

    /**
     * @todo Almost every place this is called is a pending refactor. The
     *       learning path should be mutated according to the corresponding
     *       changes that were successfully dispatched to the API, rather than
     *       reloaded from remote like this.
     */
    async refreshPath ({ commit, state }) {
      commit(SET_LEARNING_PATH, undefined)

      const learningPath = (await api.getLearningPath(state.currentLearningPathUuid)).data.data.learningPath

      commit(SET_LEARNING_PATH, learningPath)
      commit(SET_CURRENT_LEARNING_PATH_UUID, learningPath.uuid)

      if (typeof learningPath?.map?.connections !== 'undefined') {
        commit(
          SET_LEARNING_PATH_MAP_REVERSION_CONNECTIONS,
          [...learningPath.map.connections],
        )
      }

      return learningPath
    },

    setCurrentLearningPath ({ commit }, learningPathUuid) {
      commit(SET_CURRENT_LEARNING_PATH_UUID, learningPathUuid)
    },

    updateShowLearningPathNavigation ({ commit }, showLearningPathNavigation) {
      commit(SET_SHOW_LEARNING_PATH_NAVIGATION, showLearningPathNavigation)
    },
  },

  getters: {
    activityCache: state => state.activityCache,

    learningPathReversionConnections: state => state.reversionConnections,

    /**
     * @param state
     * @param _getters
     * @returns {LearningPath}
     */
    currentLearningPath: (state, _getters) => {
      if (
        typeof state.currentLearningPathUuid !== 'undefined' &&
        typeof state.learningPath !== 'undefined'
      ) {
        return Object.assign(
          state.learningPath,
          {
            // Supplement the endpoint response with some additional data.
            // TODO: Provide this directly in the endpoint, maybe through AETs.
            showDashboard: !state.learningPath.hideDashboard,
            multipleModules: !state.learningPath.singleModule,
            activities: allActivities(state.learningPath.modules),
          },
        )
      }

      return undefined
    },
    currentLearningPathUuid: state => state.currentLearningPathUuid,
  },
}

function allActivities (modules) {
  const activities = []

  modules.forEach(module => {
    if (typeof module.activities !== 'undefined') {
      module.activities.forEach(
        activity => activities.push(formatActivity(activity)),
      )
    }

    module.pages.forEach(page => {
      if (typeof page.activities !== 'undefined') {
        page.activities.forEach(
          activity => activities.push(formatActivity(activity)),
        )
      }
    })
  })

  return activities
}

function formatActivity (activity) {
  const name = `LA-${activity.id}: ${activity.label}`
  Object.assign(activity, { name: name })

  return activity
}
