import {
  SET_DRAFT_CHANGES,
  SET_LIST_VIEW,
  SET_SHOW_DRAFT_CHANGES_MODAL,
} from '@/store/mutation-types.js'
import { ViewTypes } from '@/types/enum/viewType'

export const global = {
  state: {
    /** @type {Boolean} */
    draftChanges: false,

    /** @type {ViewType} */
    viewType: ViewTypes.FOLDER,

    /** @type {Boolean} */
    showDraftChangesModal: false,
  },

  mutations: {
    /**
     * @param {Object} state
     * @param {Boolean} draftChanges
     */
    [SET_DRAFT_CHANGES] (state, draftChanges) {
      state.draftChanges = draftChanges
    },

    /**
     * @param {Object} state
     * @param {Boolean} showDraftChangesModal
     */
    [SET_SHOW_DRAFT_CHANGES_MODAL] (state, showDraftChangesModal) {
      state.showDraftChangesModal = showDraftChangesModal
    },

    /**
     * @param {String} state
     * @param {ViewType} viewType
     */
    [SET_LIST_VIEW] (state, viewType) {
      state.viewType = viewType
    },
  },

  actions: {
    setDraftChanges ({ commit }, draftChanges) {
      commit(SET_DRAFT_CHANGES, draftChanges)
    },
  },

  getters: {
    /** @return {ViewType} */
    currentViewType: state => state.viewType,

    /** @return {Boolean} */
    isDraftChanges: state => state.draftChanges,

    /** @return {Boolean} */
    showDraftChangesModal: state => state.showDraftChangesModal,
  },
}
