import {
  SET_CURRENT_RUBRIC,
  SET_CURRENT_REVERSION_RUBRIC,
  SET_CURRENT_RUBRIC_OUTCOME_ACTIVITY_CRITERIA,
  SET_CURRENT_REVERSION_RUBRIC_OUTCOME_ACTIVITY_CRITERIA,
  SET_DRAFT_CHANGES_OUTCOME_ACTIVITY_CRITERIA,
  SET_CURRENT_RUBRIC_STATE_ONLY,
} from '@/store/mutation-types.js'
import deepClone from '@/functions/helpers/deepClone'
import { activity } from '@/store/modules/activity'

export const rubric = {
  /**
   * @type {{
   *    currentRubric: Rubric,
   *    currentReversionRubric: Rubric,
   *    currentOutcomeActivityCriteria: SerializedOutcomeActivityCriteria,
   *    currentReversionOutcomeActivityCriteria: SerializedOutcomeActivityCriteria,
   *    draftChangesOutcomeActivityCriteria: Boolean
   *  }}
   */
  state: {
    currentRubric: undefined,
    currentReversionRubric: undefined,
    currentOutcomeActivityCriteria: undefined,
    currentReversionOutcomeActivityCriteria: undefined,
    draftChangesOutcomeActivityCriteria: false,
  },

  mutations: {
    /**
     * @param state
     * @param {Boolean} draftChangesOutcomeActivityCriteria
     */
    [SET_DRAFT_CHANGES_OUTCOME_ACTIVITY_CRITERIA] (
      state,
      draftChangesOutcomeActivityCriteria,
    ) {
      state.draftChangesOutcomeActivityCriteria = draftChangesOutcomeActivityCriteria
    },

    /**
     * @param state
     * @param {Rubric} rubric - Rubric can be `undefined` to clear it.
     */
    [SET_CURRENT_RUBRIC] (state, rubric) {
      if (rubric !== undefined && rubric !== null) {
        state.currentRubric = deepClone.call(rubric)
        activity.state.currentActivity.rubric = state.currentRubric.uuid
      } else if (activity.state.currentActivity !== undefined) {
        // Translate undefined or null into null, which is better for the API
        // request format this field eventually propagates to.
        state.currentRubric = undefined
        activity.state.currentActivity.rubric = null
      }
    },

    [SET_CURRENT_RUBRIC_STATE_ONLY] (state, rubric) {
      state.currentRubric = rubric
      state.currentReversionRubric = rubric
    },

    /**
     * @param state
     * @param {Rubric} rubric
     */
    [SET_CURRENT_REVERSION_RUBRIC] (state, rubric) {
      state.currentReversionRubric = deepClone.call(rubric)
    },

    /**
     * @param state
     * @param {SerializedOutcomeActivityCriteria} outcomeActivityCriteria
     */
    [SET_CURRENT_RUBRIC_OUTCOME_ACTIVITY_CRITERIA] (
      state,
      outcomeActivityCriteria,
    ) {
      state.currentOutcomeActivityCriteria = deepClone.call(
        outcomeActivityCriteria,
      )
    },

    /**
     * @param state
     * @param {SerializedOutcomeActivityCriteria} outcomeActivityCriteria
     */
    [SET_CURRENT_REVERSION_RUBRIC_OUTCOME_ACTIVITY_CRITERIA] (
      state,
      outcomeActivityCriteria,
    ) {
      state.currentReversionOutcomeActivityCriteria = deepClone.call(
        outcomeActivityCriteria,
      )
    },
  },

  getters: {
    currentRubric: state => state.currentRubric,
    currentReversionRubric: state => state.currentReversionRubric,
    currentOutcomeActivityCriteria: state => state.currentOutcomeActivityCriteria,
    currentReversionOutcomeActivityCriteria: state => state.currentReversionOutcomeActivityCriteria,
    draftChangesOutcomeActivityCriteria: state => state.draftChangesOutcomeActivityCriteria,
  },
}
