<!--suppress CssUnusedSymbol -->
<template>
  <div
    v-if="isLoggedIn === true"
    class="container"
  >
    <nav
      class="level is-mobile"
      style="margin: 8px;"
    >
      <div class="level-left">
        <div class="level-item">
          <DropdownAccount
            id="TopNavDropdownAccount"
          />
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <router-link
            v-for="link in links"
            :key="link.label"
            class="pr-3 navItem"
            :class="{ 'isActive':link.active.find(o => o === $route.name) }"
            :to="getRoute(link.name)"
          >
            {{ link.label }}
          </router-link>
        </div>
        <div class="level-item">
          <DropdownUser
            id="TopNavDropdownUser"
          />
        </div>
      </div>
    </nav>
    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import DropdownAccount from '@/components/DropdownAccount.vue'
import DropdownUser from '@/components/DropdownUser.vue'
import { ROUTE_MAP_OUTCOMES } from '@/router/routes/map'
import { ROUTE_LEARNING_PATH_PAGES_INDEX } from '@/router/routes/learningPath'

export default {
  name: 'Authenticated',
  components: {
    DropdownAccount,
    DropdownUser,
  },
  data () {
    return {
      links: [
        {
          label: 'Home',
          name: 'Dashboard',
          active: ['Dashboard'],
        },
        {
          label: 'Maps',
          name: 'CurriculumMaps',
          active: [
            'CurriculumMaps',
            'map-settings',
            ROUTE_MAP_OUTCOMES,
            'map-outcome',
            'map-lineage',
            'map-preview',
            'map-paths',
          ],
        },
        {
          label: 'Paths',
          name: 'learning-paths',
          active: [
            'learning-paths',
            'learning-path-general',
            'learning-path-outcomes',
            ROUTE_LEARNING_PATH_PAGES_INDEX,
            'learning-path-pages-overview',
            'learning-path-pages-module-page',
            'learning-path-pages-module',
            'learning-path-pages-page',
            'learning-path-pages-activity',
          ],
        },
        {
          label: 'Themes',
          name: 'themes-index',
          active: [
            'themes-index',
            'themes-show',
            'themes-advanced',
            'themes-settings',
          ],
        },
        {
          label: 'Syndication',
          name: 'syndicate-index',
          active: ['syndicate-index', 'syndicate-general', 'syndicate-delivery'],
        },
      ],
    }
  },
  computed: {
    ...mapGetters(['isLoggedIn']),
    clientUuid: function () {
      return this.$store.state.clients.currentClientUuid
    },
  },
  mounted () {
    if (process.env.NODE_ENV !== 'production') {
      document.documentElement.classList.add('isStaging')
    }
  },
  destroyed () {
    document.documentElement.classList.remove('isStaging')
  },
  methods: {
    getRoute (name) {
      return {
        name: name,
        params: { clientUuid: this.clientUuid },
      }
    },
    isActive (active) {
      if (active.find(o => o === this.$route.name)) {
        return 'pr-3 navItem isActive'
      } else {
        return 'pr-3 navItem'
      }
    },
  },
}
</script>

<style scoped>
.navItem {
  cursor: pointer;
  color: rgb(74, 74, 74);
}
.isActive {
  font-weight: bold;
  color: #1b75bb;
}
</style>
