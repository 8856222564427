import { apiClient, addAuthorizationHeader } from '@/services/api'

/**
 * Learning Path API Endpoints
 */
export default {
  createLearningPath (params) {
    return addAuthorizationHeader(apiClient).post('/api/learning-path', params)
  },

  updateLearningPath (learningPathUuid, params) {
    return addAuthorizationHeader(apiClient).put('/api/learning-path/' + learningPathUuid, params)
  },

  getLearningPath (learningPathUuid) {
    return addAuthorizationHeader(apiClient).get('/api/learning-path/' + learningPathUuid)
  },

  deleteLearningPath (learningPathUuid) {
    return addAuthorizationHeader(apiClient).delete('/api/learning-path/' + learningPathUuid)
  },

  bindMapToLearningPath (learningPathId, mapUuid) {
    return addAuthorizationHeader(apiClient).put('/api/learning-path/' + learningPathId + '/map/' + mapUuid)
  },

  unbindMapFromLearningPath (learningPathId, mapUuid) {
    return addAuthorizationHeader(apiClient).delete('/api/learning-path/' + learningPathId + '/map/' + mapUuid)
  },

  updateMapConnections (learningPathUuid, mapConnections) {
    return addAuthorizationHeader(apiClient).put(
      '/api/outcome-activities',
      { learningPath: learningPathUuid, outcomeActivities: mapConnections },
    )
  },

  cloneLearningPath (learningPathUuid, clientUuid, body) {
    return addAuthorizationHeader(apiClient).post(
      `/api/learning-path/${learningPathUuid}/clone-to/client/${clientUuid}`,
      body,
    )
  },

  /**
   * Get Learning Path Default Rubric
   *
   * @param {UUIDv7} learningPathUuid
   * @returns {Promise<{data:{data:{rubric: Rubric}}}>}
   */
  getLearningPathDefaultRubric (learningPathUuid) {
    return addAuthorizationHeader(apiClient).get(
      '/api/learning-path/' + learningPathUuid + '/default-rubric',
    )
  },

  /**
   * Get Learning Path Default Rubric Levels
   *
   * @param {UUIDv7} learningPathUuid
   * @returns {Promise<{data:{data:{defaultRubricLevels: RubricLevelDefault[]}}}>}
   */
  getLearningPathDefaultRubricLevels (learningPathUuid) {
    return addAuthorizationHeader(apiClient).get(
      '/api/learning-path/' + learningPathUuid + '/default-rubric-levels',
    )
  },

  /**
   * Upsert Learning Path Default Rubric Levels
   *
   * @param {UUIDv7} learningPathUuid
   */
  upsertLearningPathDefaultRubricLevels (learningPathUuid, defaultRubricLevels) {
    return addAuthorizationHeader(apiClient).put(
      '/api/learning-path/' + learningPathUuid + '/upsert-default-rubric-levels',
      defaultRubricLevels,
    )
  },
}
