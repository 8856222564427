import { apiClient, addAuthorizationHeader } from '@/services/api'

/**
 * Client API Endpoints
 */
export default {
  getClient (clientUuid) {
    return addAuthorizationHeader(apiClient).get('/api/client/' + clientUuid)
  },

  getClientLearningPathsRecent (clientUuid) {
    return addAuthorizationHeader(apiClient).get(`/api/client/${clientUuid}/learning-paths/recent`)
  },

  getClientMapsRecent (clientUuid) {
    return addAuthorizationHeader(apiClient).get(`/api/client/${clientUuid}/maps/recent`)
  },

  getClientLearningPaths (clientUuid) {
    return addAuthorizationHeader(apiClient).get(`/api/client/${clientUuid}/learning-paths`)
  },

  getClientMaps (clientUuid) {
    return addAuthorizationHeader(apiClient).get(`/api/client/${clientUuid}/maps`)
  },

  getClientThemes (clientUuid) {
    return addAuthorizationHeader(apiClient).get(`/api/client/${clientUuid}/themes`)
  },

  getClientUsage (clientUuid) {
    return addAuthorizationHeader(apiClient).get(`/api/client/${clientUuid}/usage`)
  },

  createClient (params) {
    return addAuthorizationHeader(apiClient).post('/api/client', params)
  },

  updateClient (clientUuid, params) {
    return addAuthorizationHeader(apiClient).put('/api/client/' + clientUuid, params)
  },

  addUserToClient (clientUuid, userId, role) {
    return addAuthorizationHeader(apiClient).post(
      `/api/client/${clientUuid}/add-user/${userId}/as/${role}`,
    )
  },

  updateUserRole (clientUuid, userId, role) {
    return addAuthorizationHeader(apiClient).put(
      `/api/client/${clientUuid}/change-user-role/${userId}/to/${role}`,
    )
  },

  removeUserFromClient (clientUuid, userId) {
    return addAuthorizationHeader(apiClient).delete(
      `/api/client/${clientUuid}/remove-user/${userId}`,
    )
  },
}
