import { apiClient, addAuthorizationHeader } from '@/services/api'

export default {
  // Tag categories
  createTagCategory (params) {
    return addAuthorizationHeader(apiClient).post('/api/tag-category', params)
  },
  updateTagCategory (tagCategoryUuid, params) {
    return addAuthorizationHeader(apiClient).put(`/api/tag-category/${tagCategoryUuid}`, params)
  },
  deleteTagCategory (tagCategoryUuid) {
    return addAuthorizationHeader(apiClient).delete(`/api/tag-category/${tagCategoryUuid}`)
  },

  // Tag
  createTag (params) {
    return addAuthorizationHeader(apiClient).post('/api/tag', params)
  },
  updateTag (tagUuid, params) {
    return addAuthorizationHeader(apiClient).put(`/api/tag/${tagUuid}`, params)
  },
  deleteTag (tagUuid) {
    return addAuthorizationHeader(apiClient).delete(`/api/tag/${tagUuid}`)
  },

  // Tag search
  getClientTagCategories (clientUuid) {
    return addAuthorizationHeader(apiClient).get(`/api/client/${clientUuid}/tag-categories`)
  },
  getClientTags (clientUuid) {
    return addAuthorizationHeader(apiClient).get(`/api/client/${clientUuid}/tags`)
  },

  // Tag assignments
  addTagToLearningPath (learningPathUuid, tagUuid) {
    return addAuthorizationHeader(apiClient).post('/api/learning-path/' + learningPathUuid + '/add-tag/' + tagUuid)
  },
  removeTagFromLearningPath (learningPathUuid, tagUuid) {
    return addAuthorizationHeader(apiClient).delete('/api/learning-path/' + learningPathUuid + '/remove-tag/' + tagUuid)
  },
  addTagToMap (mapUuid, tagUuid) {
    return addAuthorizationHeader(apiClient).post('/api/map/' + mapUuid + '/add-tag/' + tagUuid)
  },
  removeTagFromMap (mapUuid, tagUuid) {
    return addAuthorizationHeader(apiClient).delete('/api/map/' + mapUuid + '/remove-tag/' + tagUuid)
  },
  addTagToTheme (themeId, tagUuid) {
    return addAuthorizationHeader(apiClient).post('/api/theme/' + themeId + '/add-tag/' + tagUuid)
  },
  removeTagFromTheme (themeId, tagUuid) {
    return addAuthorizationHeader(apiClient).delete('/api/theme/' + themeId + '/remove-tag/' + tagUuid)
  },
  addTagToL8yItem (itemId, tagUuid) {
    return addAuthorizationHeader(apiClient).post('/api/l8y-item/' + itemId + '/add-tag/' + tagUuid)
  },
  removeTagFromL8yItem (itemId, tagUuid) {
    return addAuthorizationHeader(apiClient).delete('/api/l8y-item/' + itemId + '/remove-tag/' + tagUuid)
  },
}
