import axios from 'axios'
import router from '@/router'

// Routes
import activity from '@/services/api/activity'
import client from '@/services/api/client'
import fileUpload from '@/services/api/fileUpload'
import learningPath from '@/services/api/learningPath'
import map from '@/services/api/map'
import outcome from '@/services/api/outcome'
import outcomeActivityActivityL8yItem from '@/services/api/outcomeActivityActivityL8yItem'
import page from '@/services/api/page'
import rubric from '@/services/api/rubric'
import tag from '@/services/api/tag'

export const apiClient = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

export const apiClientForm = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: false,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
})

// Error redirect
apiClient.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      console.log('Unauthorized access')
      // noinspection JSIgnoredPromiseFromCall
      router.push('/login?status=401')
    } else if (is500error(error)) {
      router.push('/error').catch(console.error)
    }

    return Promise.reject(error)
  })

apiClientForm.interceptors.response.use(null, error => {
  if (is500error(error)) {
    router.push('/error').catch(console.error)
  }

  return Promise.reject(error)
})

export default {
  ...activity,
  ...client,
  ...fileUpload,
  ...learningPath,
  ...map,
  ...outcome,
  ...outcomeActivityActivityL8yItem,
  ...page,
  ...rubric,
  ...tag,

  getFromLocalStorage (name, defaultValue) {
    if (localStorageIsAvailable()) {
      return localStorage.getItem(name) || defaultValue
    } else {
      return defaultValue
    }
  },

  // Tokens
  getAccessToken (params) {
    return apiClient.post('/api/token', params)
  },
  refreshAccessToken (params) {
    return apiClient.post('/api/token/refresh', params)
  },

  // User
  async getUser (userId) {
    return (await addAuthorizationHeader(apiClient).get(`/api/user/${userId}`)).data
  },

  // Skill & credential tags
  getSkillFacets (query) {
    return addAuthorizationHeader(apiClient).get(`/api/facet${query}`)
  },
  getSkillList (query) {
    return addAuthorizationHeader(apiClient).get(`/api/skills${query}`)
  },

  // User
  createUser (params) {
    return addAuthorizationHeader(apiClient).post('/api/user', params)
  },
  updateUser (userId, params) {
    return addAuthorizationHeader(apiClient).put('/api/user/' + userId, params)
  },
  findUserByEmail (searchTerm) {
    return addAuthorizationHeader(apiClient).post('/api/user/search-by/email/' + searchTerm)
  },

  // LTI links
  createLtiLink (params) {
    return addAuthorizationHeader(apiClient).post('/api/lti-link', params)
  },
  updateLtiLink (ltiLinkId, params) {
    return addAuthorizationHeader(apiClient).put('/api/lti-link/' + ltiLinkId, params)
  },
  deleteLtiLink (ltiLinkId) {
    return addAuthorizationHeader(apiClient).delete('/api/lti-link/' + ltiLinkId)
  },

  // Themes
  createTheme (params) {
    return addAuthorizationHeader(apiClient).post('/api/theme', params)
  },
  updateTheme (themeId, params) {
    return addAuthorizationHeader(apiClient).put('/api/theme/' + themeId, params)
  },
  deleteTheme (themeId) {
    return addAuthorizationHeader(apiClient).delete('/api/theme/' + themeId)
  },

  // Deep links
  getLinks (query, token) {
    return refreshDeepLinkToken(apiClient, token).get(
      '/api/lti-link',
      { params: query },
    )
  },
  getDeepLinkClients (token) {
    return refreshDeepLinkToken(apiClient, token).get('/api/deep-link/clients')
  },

  // Modules
  createModule (params) {
    return addAuthorizationHeader(apiClientForm).post('/api/module', params)
  },
  updateModule (moduleId, params) {
    return addAuthorizationHeader(apiClientForm).post('/api/module/' + moduleId, params)
  },
  deleteModule (moduleId) {
    return addAuthorizationHeader(apiClient).delete('/api/module/' + moduleId)
  },
  moveModule (movingModuleId, referenceModuleId) {
    return addAuthorizationHeader(apiClient).put(
      `/api/module/${movingModuleId}/move-to/${referenceModuleId}`,
    )
  },

  // L8y activity operations for l8yItems and assessmentL8yItems.
  addL8yItemToActivity (activityUuid, itemL8yReference) {
    return addAuthorizationHeader(apiClient).post(
      `/api/activity/l8y/${activityUuid}/add/l8y-reference-item/${itemL8yReference}`,
    )
  },
  addL8yItemsToActivity (activityUuid, params) {
    return addAuthorizationHeader(apiClient).post(
      `/api/activity/l8y/${activityUuid}/add-l8y-items`,
      params,
    )
  },
  updateActivityScores (activityUuid, params) {
    return addAuthorizationHeader(apiClient).post(
      `/api/activity/l8y/${activityUuid}/question-scores`,
      params,
    )
  },

  // AssessmentL8yItems
  updateAssessmentL8yItem (itemId) {
    return addAuthorizationHeader(apiClient).put(`/api/assessment-l8y-item/${itemId}`)
  },
  removeAssessmentL8yItem (itemId) {
    return addAuthorizationHeader(apiClient).delete(`/api/assessment-l8y-item/${itemId}`)
  },
  moveAssessmentL8yItem (movingAssessmentL8yItemId, referenceAssessmentL8yItemId) {
    return addAuthorizationHeader(apiClient).post(
      `/api/assessment-l8y-item/${movingAssessmentL8yItemId}/move-to/${referenceAssessmentL8yItemId}`,
    )
  },

  // Learnosity items
  getL8yItems (params) {
    const query = new URLSearchParams(params)
    const queryStr = decodeURIComponent(query.toString())
    return addAuthorizationHeader(apiClient).get('/api/l8y-item?' + queryStr)
  },
  updateL8yItem (itemId) {
    return addAuthorizationHeader(apiClient).put(`/api/l8y-item/${itemId}`)
  },
  removeL8yItem (itemId) {
    return addAuthorizationHeader(apiClient).delete(`/api/l8y-item/${itemId}`)
  },

  // Discussion topics
  createTopic (params) {
    return addAuthorizationHeader(apiClient).post('/api/discussion/topic', params)
  },
  updateTopic (topicId, params) {
    return addAuthorizationHeader(apiClient).put(
      `/api/discussion/topic/${topicId}`,
      params,
    )
  },
  deleteTopic (topicId) {
    return addAuthorizationHeader(apiClient).delete(`/api/discussion/topic/${topicId}`)
  },

  // Questions
  changeQuestionType (questionId, newQuestionType) {
    return addAuthorizationHeader(apiClient).post(
      `/api/question/${questionId}/change-to/${newQuestionType}`,
    )
  },
  createQuestionShort (params) {
    return addAuthorizationHeader(apiClient).post('/api/question/short-answer', params)
  },
  createQuestionMultiple (params) {
    return addAuthorizationHeader(apiClient).post('/api/question/multiple-choice', params)
  },
  updateQuestionShort (questionId, params) {
    return addAuthorizationHeader(apiClient).put('/api/question/short-answer/' + questionId, params)
  },
  updateQuestionMultiple (questionId, params) {
    return addAuthorizationHeader(apiClient).put('/api/question/multiple-choice/' + questionId, params)
  },
  deleteQuestionShort (questionId) {
    return addAuthorizationHeader(apiClient).delete('/api/question/short-answer/' + questionId)
  },
  deleteQuestionMultiple (questionId) {
    return addAuthorizationHeader(apiClient).delete('/api/question/multiple-choice/' + questionId)
  },

  // Choices
  createChoice (params) {
    return addAuthorizationHeader(apiClient).post('/api/choice', params)
  },
  updateChoice (choiceId, params) {
    return addAuthorizationHeader(apiClient).put('/api/choice/' + choiceId, params)
  },
  deleteChoice (choiceId) {
    return addAuthorizationHeader(apiClient).delete('/api/choice/' + choiceId)
  },

  // Admin
  getLtiUsers (query) {
    return addAuthorizationHeader(apiClient).get('/api/admin/lti-users', { params: query })
  },

  // Attempts
  getAttempts (query) {
    return addAuthorizationHeader(apiClient).get('/api/admin/attempts', { params: query })
  },

  deleteActivityAttempts (activityId) {
    return addAuthorizationHeader(apiClient).delete(`/api/activities/${activityId}/attempts/all`)
  },

  deleteAttempt (attemptId) {
    return addAuthorizationHeader(apiClient).delete(`/api/attempt/${attemptId}`)
  },

  // LTI tools
  getClientLtiTools (clientUuid) {
    return addAuthorizationHeader(apiClient).get(`/api/client/${clientUuid}/lti1p3-tool`)
  },

  createLtiTool (clientUuid, params) {
    return addAuthorizationHeader(apiClient).post(`/api/client/${clientUuid}/lti1p3-tool`, params)
  },

  updateLtiTool (clientUuid, lti1p3ToolId, params) {
    return addAuthorizationHeader(apiClient).put(
      `/api/client/${clientUuid}/lti1p3-tool/${lti1p3ToolId}`,
      params,
    )
  },

  // Deployments
  getDeployments (clientUuid) {
    return addAuthorizationHeader(apiClient).get(`/api/admin/deployments?client=${clientUuid}`)
  },
  adminGetDeployments (query) {
    return addAuthorizationHeader(apiClient).get('/api/admin/deployments', { params: query })
  },
  deleteDeployment (deploymentId) {
    return addAuthorizationHeader(apiClient).delete('/api/deployment/' + deploymentId)
  },
  createDeployment (params) {
    return addAuthorizationHeader(apiClient).post('/api/deployment', params)
  },
  updateDeployment (deploymentId, params) {
    return addAuthorizationHeader(apiClient).put('/api/deployment/' + deploymentId, params)
  },

  // Get Learnosity signature
  getLearnositySignature (codedToken) {
    const domain = process.env.VUE_APP_DESIGNER_DOMAIN
    return addAuthorizationHeader(apiClient).get(
      `/api/learnosity-signature?learnosityApiRequest=${codedToken}&domain=${domain}`,
    )
  },
}

function refreshDeepLinkToken (apiClient, token) {
  apiClient.defaults.headers.common.Authorization = `Bearer ${token}`
  return apiClient
}

export function addAuthorizationHeader (apiClient) {
  apiClient.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('token')}`
  return apiClient
}

function is500error (error) {
  if (typeof error !== 'undefined' && typeof error.status !== 'undefined') {
    return Number(error.status) === 500
  }

  if (
    typeof error !== 'undefined' &&
    typeof error.data !== 'undefined' &&
    typeof error.data.status !== 'undefined'
  ) {
    return Number(error.data.status) === 500
  }

  if (
    typeof error !== 'undefined' &&
    typeof error.response !== 'undefined' &&
    typeof error.response.status !== 'undefined'
  ) {
    return Number(error.response.status) === 500
  }

  if (
    typeof error !== 'undefined' &&
    typeof error.response !== 'undefined' &&
    typeof error.response.data !== 'undefined' &&
    typeof error.response.data.status !== 'undefined'
  ) {
    return Number(error.response.data.status) === 500
  }

  return false
}

function localStorageIsAvailable () {
  try {
    localStorage.setItem('test', 'test')
    localStorage.removeItem('test')
    return true
  } catch (e) {
    return false
  }
}
