import { castIntegerRouteParams } from '../index'

/**
 * Learning Path Routes
 *
 * @type RouteName
 */
export const ROUTE_LEARNING_PATH_OUTCOMES_DETAILS_ACTIVITY = 'learning-path-outcomes-details-activity'

/** @type RouteName */
export const ROUTE_LEARNING_PATH_OUTCOMES_DETAILS_OUTCOME = 'learning-path-outcomes-details-outcome'

/** @type RouteName */
export const ROUTE_LEARNING_PATH_PAGES_INDEX = 'learning-path-pages-index'

/** @return {RouteRecordSingleView[]} */
export default [
  {
    path: '/client/:clientUuid/learning-paths',
    name: 'learning-paths',
    meta: { requiresAuth: true },
    component: () => import('../../views/LearningPaths.vue'),
    props: true,
  },
  {
    path: '/client/:clientUuid/learning-path/:learningPathUuid/settings/general',
    name: 'learning-path-general',
    meta: { requiresAuth: true },
    component: () => import('../../views/path/settings/General.vue'),
    props: true,
  },
  {
    path: '/client/:clientUuid/learning-path/:learningPathUuid/settings/image-card',
    name: 'learning-path-image-card',
    meta: { requiresAuth: true },
    component: () => import('../../views/path/settings/Image.vue'),
    props: true,
  },
  {
    path: '/client/:clientUuid/learning-path/:learningPathUuid/settings/forum',
    name: 'learning-path-forum',
    meta: { requiresAuth: true },
    component: () => import('../../views/path/settings/Forum.vue'),
    props: true,
  },
  {
    path: '/client/:clientUuid/learning-path/:learningPathUuid/settings/default-rubric',
    name: 'learning-path-default-rubric-levels',
    meta: { requiresAuth: true },
    component: () => import('../../views/path/settings/DefaultRubricLevels.vue'),
    props: true,
  },
  {
    path: '/client/:clientUuid/learning-path/:learningPathUuid/outcomes',
    name: 'learning-path-outcomes',
    meta: { requiresAuth: true },
    component: () => import('../../views/LearningPathOutcomes.vue'),
    children: [
      {
        path: 'details-activity/:activityUuid',
        name: ROUTE_LEARNING_PATH_OUTCOMES_DETAILS_ACTIVITY,
        meta: { requiresAuth: true },
      },
      {
        path: 'details-outcome/:outcomeUuid',
        name: ROUTE_LEARNING_PATH_OUTCOMES_DETAILS_OUTCOME,
        meta: { requiresAuth: true },
      },
    ],
  },

  {
    path: '/client/:clientUuid/learning-path/:learningPathUuid',
    name: ROUTE_LEARNING_PATH_PAGES_INDEX,
    meta: { requiresAuth: true },
    component: () => import('../../views/path/pages/Index.vue'),
    props: true,
  },

  {
    path: '/client/:clientUuid/learning-path/:learningPathUuid/overview',
    name: 'learning-path-pages-overview',
    meta: { requiresAuth: true },
    component: () => import('../../views/path/pages/Overview.vue'),
    props: true,
  },
  {
    path: '/client/:clientUuid/learning-path/:learningPathUuid/module/:moduleId',
    name: 'learning-path-pages-module',
    meta: { requiresAuth: true },
    component: () => import('../../views/path/pages/Module.vue'),
    props: castIntegerRouteParams,
  },
]
