import {
  ADD_PENDING_FILE_UPLOAD,
  SET_CURRENT_ACTIVITY,
  SET_CURRENT_REVERSION_ACTIVITY,
  SET_DRAFT_CHANGES,
  SET_OPTION,
  SET_ACTIVITY_SAVE_SUCCESSFUL,
} from '@/store/mutation-types.js'

import api from '@/services/api.js'

import deepClone from '@/functions/helpers/deepClone'
import deepValue from '@/functions/helpers/deepValue'
import updateActivity from '@/functions/updateActivity'

export const activity = {
  state: {
    /** @type {UUIDv7} */
    currentActivityUuid: undefined,

    /** @type {Activity} */
    currentActivity: undefined,

    /** @type {Activity} */
    currentReversionActivity: undefined,

    /**
     * @type {Boolean} activitySaveSuccessful
     *                Used to trigger error modal in the component calling the
     *                activity component if/when activity save fails in the API.
     */
    activitySaveSuccessful: true,

    /**
     * Pending File Uploads
     *
     * @todo Maybe move this to a higher level to work for all uploads,
     *       not limited to activities.
     * @type {PendingFileUpload[]}
     */
    pendingFileUploads: [],
  },
  mutations: {
    /**
     * @param state
     * @param {Activity} activity
     */
    [SET_CURRENT_ACTIVITY] (state, activity) {
      state.currentActivity = activity
    },

    /**
     * @param state
     * @param {Activity} activity
     */
    [SET_CURRENT_REVERSION_ACTIVITY] (state, activity) {
      state.currentReversionActivity = activity
    },

    /**
     * Set Option
     *
     * For example, providing 'foo.bar.baz' with value 'bat' will do:
     * state.currentActivity.foo.bar.baz = 'bat'
     *
     * @param state
     * @param {Object} option
     * @param {String} option.name The path on the activity to set a value on.
     * @param {any} option.value The value to set.
     */
    [SET_OPTION] (state, option) {
      deepValue.set(state.currentActivity, option.name, option.value)
    },

    [ADD_PENDING_FILE_UPLOAD] (state, uploadFile) {
      state.pendingFileUploads.push(uploadFile)
    },

    /**
     * @param state
     * @param {Boolean} outcomeCreationStatus
     */
    [SET_ACTIVITY_SAVE_SUCCESSFUL] (state, outcomeCreationStatus) {
      state.activitySaveSuccessful = outcomeCreationStatus
    },
  },
  actions: {
    async refreshActivity ({ commit, state }) {
      try {
        const response = await api.getActivity(state.currentActivity.uuid)

        commit(SET_CURRENT_ACTIVITY, response.data.data.activity)

        return response.data.data.activity
      } catch (e) {
        commit(SET_CURRENT_ACTIVITY, undefined)
        throw e
      }
    },

    /**
     * Add Pending File Upload
     *
     * @param {PendingFileUpload} pendingFileUpload
     * @param commit
     */
    addPendingFileUpload ({ commit }, pendingFileUpload) {
      commit(ADD_PENDING_FILE_UPLOAD, pendingFileUpload)
    },

    /**
     * This action will toggle the `hasAssociatedDiscussion` property of an activity. Afterward, it will ensure that
     * anything related to conversations are updated within the current store.
     *
     * @param {Function} commit
     * @param {Object} getters
     * @returns {Promise<void>}
     */
    async toggleHasAssociatedDiscussion ({ commit, getters }) {
      // Toggle the setting on the activity.
      await updateActivity.call(
        getters.currentActivity.type,
        getters.currentActivity.uuid,
        { hasAssociatedDiscussion: !getters.currentActivity.hasAssociatedDiscussion },
      )

      // Load the new activity's data into the state
      const activity = (await api.getActivity(getters.currentActivity.uuid)).data.data.activity
      commit(SET_OPTION, {
        name: 'associatedDiscussionData',
        value: activity.associatedDiscussionData,
      })
      commit(SET_OPTION, {
        name: 'conversationTopics',
        value: activity.conversationTopics,
      })
      commit(SET_OPTION, {
        name: 'hasAssociatedDiscussion',
        value: activity.hasAssociatedDiscussion,
      })
    },

    toggleIsPreAssessment ({ commit, getters }, isPreAssessment) {
      commit(SET_DRAFT_CHANGES, true)

      /**
       * @todo Currently the setOption mixin we use for most activity changes doesn't work well with the checked
       *       attribute for checkboxes so this action was created. Later we can update the setOption mixin to handle
       *       checkboxes.
       */
      commit(
        SET_OPTION,
        {
          name: 'isPreAssessment',
          value: isPreAssessment,
        },
      )
    },

    toggleHideCorrectAnswersInProgress (
      { commit, getters },
      hideCorrectAnswersInProgress,
    ) {
      commit(SET_DRAFT_CHANGES, true)

      commit(
        SET_OPTION,
        {
          name: 'hideCorrectAnswersInProgress',
          value: hideCorrectAnswersInProgress,
        },
      )
    },
  },
  getters: {
    /** @return {Boolean} */
    activitySaveSuccessful: (state) => {
      return state.activitySaveSuccessful
    },

    /** @return {PendingFileUpload[]} */
    pendingFileUploads: state => state.pendingFileUploads,

    currentReversionActivity: state => state.currentReversionActivity,

    /** @return {ActivityVideo} -- Exists only for typing. */
    currentActivityVideo: function (state) {
      // noinspection JSValidateTypes
      return state.currentActivity
    },

    /** @return {ActivityQuiz | ActivitySurvey} -- Exists only for typing. */
    currentActivityQuizSurvey: function (state) {
      // noinspection JSValidateTypes
      return state.currentActivity
    },

    /** @return {Activity|ActivityL8y} */
    currentActivity: state => {
      if (typeof state.currentActivity !== 'undefined') {
        /** @type {Activity} */
        const activity = deepClone.call(state.currentActivity)

        // Set default Learnosity Type if NULL.
        const learnosityType = activity.learnosityType === null
          ? 'activity'
          : activity.learnosityType

        /**
         * lockedDueToAttempts is used by the activity editing view to block
         * (via input disable) changes to activity properties that affect grades
         * after 1 or more learner attempts have been made on the activity. It
         * also triggers a warning message.
         */
        const lockedDueToAttempts = activity.numberOfAttempts > 0

        return Object.assign(activity, {
          learnosityType: learnosityType,

          /**
           * lockedDueToAttempts is something we figured out on the frontend
           * rather than being fed directly by the API, so we add it here to the
           * larger `activity` object (most of which comes direct from API).
           */
          lockedDueToAttempts: lockedDueToAttempts,
        })
      }

      return undefined
    },

    currentActivityGradingScheme: function (state, getters) {
      return getters.currentActivity?.gradingScheme
    },

    currentActivityShowFeedback: function (state, getters) {
      return getters.currentActivity?.showFeedback
    },
  },
}
