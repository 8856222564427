import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import activityRoutes from './routes/activity'
import learningPathRoutes from './routes/learningPath'
import mapRoutes from './routes/map'
import pageRoutes from './routes/page'

Vue.use(VueRouter)

Vue.mixin({
  beforeRouteLeave (to, from, next) {
    if (store.state.global.draftChanges) {
      // TODO: Convert this to a proper modal.
      const answer = window.confirm(
        'Do you really want to leave? You have unsaved changes!',
      )

      if (answer) {
        // TODO: Properly handle this promise.
        // noinspection JSIgnoredPromiseFromCall
        store.dispatch('setDraftChanges', false)
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },
})

// TODO: Convert PascalCase route names to kebab-case
let routes = [
  ...activityRoutes,
  ...learningPathRoutes,
  ...mapRoutes,
  ...pageRoutes,
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
  },
  {
    path: '/client/:clientUuid/',
    name: 'Dashboard',
    meta: { requiresAuth: true },
    component: () => import('../views/Dashboard.vue'),
    props: true,
  },

  {
    path: '/client/:clientUuid/learning-path/:learningPathUuid/publish',
    name: 'LearningPathPublish',
    meta: { requiresAuth: true },
    component: () => import('../views/LearningPathPublish.vue'),
    props: true,
  },
  {
    path: '/client/:clientUuid/syndicate',
    name: 'syndicate-index',
    meta: { requiresAuth: true },
    component: () => import('../views/syndication/Index.vue'),
    props: true,
  },
  {
    path: '/client/:clientUuid/learning-path/:learningPathUuid/syndicate/:ltiLinkId',
    name: 'syndicate-general',
    meta: { requiresAuth: true },
    component: () => import('../views/syndication/General.vue'),
    props: castIntegerRouteParams,
  },
  {
    path: '/client/:clientUuid/learning-path/:learningPathUuid/syndicate/:ltiLinkId/delivery',
    name: 'syndicate-delivery',
    meta: { requiresAuth: true },
    component: () => import('../views/syndication/Delivery.vue'),
    props: castIntegerRouteParams,
  },
  {
    path: '/client/:clientUuid/themes',
    name: 'themes-index',
    meta: { requiresAuth: true },
    component: () => import('../views/themes/Index.vue'),
    props: true,
  },
  {
    path: '/client/:clientUuid/themes/:id',
    name: 'themes-show',
    meta: { requiresAuth: true },
    component: () => import('../views/themes/Show.vue'),
    props: castIntegerRouteParams,
  },
  {
    path: '/client/:clientUuid/themes/:id/advanced',
    name: 'themes-advanced',
    meta: { requiresAuth: true },
    component: () => import('../views/themes/Advanced.vue'),
    props: castIntegerRouteParams,
  },
  {
    path: '/client/:clientUuid/themes/:id/links',
    name: 'themes-links',
    meta: { requiresAuth: true },
    component: () => import('../views/themes/Links.vue'),
    props: castIntegerRouteParams,
  },
  {
    path: '/client/:clientUuid/themes/:id/settings',
    name: 'themes-settings',
    meta: { requiresAuth: true },
    component: () => import('../views/themes/Settings.vue'),
    props: castIntegerRouteParams,
  },
  {
    path: '/client/:clientUuid/profile',
    name: 'Profile',
    meta: { requiresAuth: true },
    component: () => import('../views/settings/Profile.vue'),
    props: true,
  },
  {
    path: '/client/:clientUuid/general',
    name: 'General',
    meta: { requiresAuth: true },
    component: () => import('../views/settings/General.vue'),
    props: true,
  },
  {
    path: '/client/:clientUuid/tags',
    name: 'Tags',
    meta: { requiresAuth: true },
    component: () => import('../views/settings/Tags.vue'),
    props: true,
  },
  {
    path: '/client/:clientUuid/billing',
    name: 'Billing',
    meta: { requiresAuth: true },
    component: () => import('../views/settings/Billing.vue'),
    props: true,
  },
  {
    path: '/client/:clientUuid/lti-tools',
    name: 'LTITools',
    meta: { requiresAuth: true },
    component: () => import('../views/settings/LTITools.vue'),
    props: true,
  },
  {
    path: '/client/:clientUuid/team',
    name: 'Team',
    meta: { requiresAuth: true },
    component: () => import('../views/settings/Team.vue'),
    props: true,
  },
  {
    path: '/client/:clientUuid/new',
    name: 'NewClient',
    meta: { layout: 'unauthenticated', requiresAuth: true },
    component: () => import('../views/onboard/ClientNew.vue'),
    props: true,
  },
  {
    path: '/lti',
    name: 'LTI',
    meta: { layout: 'blank', requiresAuth: true },
    component: () => import('../views/LTI.vue'),
    props: true,
  },
  {
    path: '/deeplinks',
    name: 'DeepLinks',
    meta: { layout: 'blank', requiresAuth: false },
    component: () => import('../views/syndication/DeepLinks.vue'),
    props: true,
  },
  {
    path: '/admin/users',
    name: 'AdminLtiUsers',
    meta: { layout: 'blank', requiresAuth: true },
    component: () => import('../views/admin/users.vue'),
    props: true,
  },
  {
    path: '/admin/attempts',
    name: 'AdminAttempts',
    meta: { layout: 'blank', requiresAuth: true },
    component: () => import('../views/admin/attempts.vue'),
    props: true,
  },
  {
    path: '/admin/deployments',
    name: 'AdminDeployments',
    meta: { layout: 'blank', requiresAuth: true },
    component: () => import('../views/admin/deployments.vue'),
    props: true,
  },
  {
    path: '*',
    component: () => import('../views/NotFound.vue'),
  },
]

/**
 * Cast integer route params
 *
 * These route params are often passed as props to components. Cast them to the
 * correct integer type when doing so, since by default they're otherwise
 * provided as strings.
 *
 * @todo Get rid of this when UUIDs are universal.
 * @param route
 * @returns {*&{ltiLinkId, id, moduleId, pageId}}
 */
export function castIntegerRouteParams (route) {
  const moduleId = ['overview', 'new'].includes(route.params.moduleId)
    ? route.params.moduleId : Number(route.params.moduleId) || undefined

  const pageId = ['overview', 'new'].includes(route.params.pageId)
    ? route.params.pageId : Number(route.params.pageId) || undefined

  return {
    ...route.params,
    ...{ moduleId: moduleId },
    ...{ pageId: pageId },
    ...{ ltiLinkId: Number(route.params.ltiLinkId) || undefined },
    ...{ id: Number(route.params.id) || undefined },
  }
}

if (process.env.VUE_APP_MAINTENANCE_MODE_ENABLED === 'true') {
  routes = [{
    path: '*',
    meta: { layout: 'blank', requiresAuth: false },
    component: () => import('../views/MaintenanceMode.vue'),
  }]
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
})

router.beforeEach(async (to, _from, next) => {
  let nextParams = {}

  if (to.matched.some(record => record.meta.requiresAuth === true)) {
    if ('token' in to.query) {
      await store.dispatch('loginWithQueryStringToken')
    }

    // noinspection JSUnresolvedReference
    if (
      localStorage.getItem('token') !== null &&
      store.state.auth.clientAssignments.length === 0
    ) {
      await store.dispatch('loginWithLocalStorageToken')
    }

    if (store.getters.isLoggedIn !== true) {
      nextParams = {
        path: '/login',
        query: { redirect: to.fullPath },
      }
    } else {
      updateStores(to, store)
    }
  }

  next(nextParams)
})

/**
 * @todo Why are we doing this huge amount of work on every route change?
 *       Many (all?) of these dispatch() calls in turn fire API requests to
 *       load the related entities into VueX state. Looking at these route
 *       params and then firing these requests needs to be moved to
 *       beforeMount() calls in the COMPONENTS not here on every route change.
 */
function updateStores (to, store) {
  // If the route has a parameter called 'clientUuid'
  if (typeof to.params.clientUuid !== 'undefined') {
    const currentClientUuid = to.params.clientUuid

    if (currentClientUuid !== undefined) {
      if (currentClientUuid !== store.getters.currentClientUuid) {
        store.dispatch('setIndexLoading')
        store.dispatch('setCurrentClientUuid', currentClientUuid)
      } else if (!store.getters.reloaded) {
        // Likely a hot refresh inside a resource page.
        store.dispatch('setCurrentClientUuid', currentClientUuid)
      }

      if (to.name === 'Dashboard') {
        store.dispatch('getClientDashboard', currentClientUuid)
      }

      if (to.name === 'learning-paths') {
        store.dispatch('getClientLearningPaths', currentClientUuid)
      }

      if (to.name === 'themes-index') {
        store.dispatch('getClientThemes', currentClientUuid)
      }

      if (to.name === 'syndicate-index') {
        store.dispatch('getClientLearningPaths', currentClientUuid)
      }

      if (
        to.name === 'General' ||
        to.name === 'Billing' ||
        to.name === 'Tags' ||
        to.name === 'Team' ||
        to.name === 'LTITools'
      ) {
        store.dispatch('getClient', currentClientUuid)
      }

      if (to.name === 'Billing') {
        store.dispatch('getClientUsage', currentClientUuid)
      }
    }
  }

  if (
    typeof to.params.clientUuid !== 'undefined' &&
    typeof to.params.learningPathUuid !== 'undefined'
  ) {
    const currentClientUuid = to.params.clientUuid
    const paramsLearningPathUuid = to.params.learningPathUuid

    if (
      paramsLearningPathUuid !== undefined &&
      paramsLearningPathUuid !== 'new' &&
      store.getters.currentLearningPathUuid !== paramsLearningPathUuid
    ) {
      store.dispatch('getLearningPath', {
        learningPathUuid: paramsLearningPathUuid,
        currentClientUuid: currentClientUuid,
      })
    }
  }

  if (typeof to.params.ltiLinkId !== 'undefined') {
    const ltiLinkId = Number(to.params.ltiLinkId)

    if (!Number.isNaN(ltiLinkId)) {
      store.dispatch('setCurrentLtiLinkId', ltiLinkId)
    }
  }
}

export default router
