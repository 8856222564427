/**
 * View Type
 *
 * @typedef {
 *  ViewTypes.FOLDER |
 *  ViewTypes.GRID |
 *  ViewTypes.LIST
 *  } ViewType
 */

export const ViewTypes = Object.freeze({
  FOLDER: 'folder',
  GRID: 'grid',
  LIST: 'list',
})
