/**
 * Map Routes
 *
 * @todo Convert the rest of the route names and codebase references to
 *       constants.
 */

/** @var {RouteName} ROUTE_MAPS */
export const ROUTE_MAPS = 'CurriculumMaps'

/** @var {RouteName} ROUTE_MAP_OUTCOMES */
export const ROUTE_MAP_OUTCOMES = 'map-outcomes'

/** @return {RouteRecordSingleView[]} */
export default [
  /** View Maps */
  {
    path: '/client/:clientUuid/maps',
    name: ROUTE_MAPS,
    meta: { requiresAuth: true },
    component: () => import('../../views/CurriculumMaps.vue'),
    props: true,
  },

  /** Map Settings */
  {
    path: '/client/:clientUuid/map/:curriculumMapUuid/settings',
    name: 'map-settings',
    meta: { requiresAuth: true },
    component: () => import('../../views/map/Settings.vue'),
    props: true,
  },

  /** Learning Paths Bounds to a Map */
  {
    path: '/client/:clientUuid/map/:curriculumMapUuid/paths',
    name: 'map-paths',
    meta: { requiresAuth: true },
    component: () => import('../../views/map/Paths.vue'),
    props: true,
  },

  /** View Map */
  {
    path: '/client/:clientUuid/map/:curriculumMapUuid',
    name: ROUTE_MAP_OUTCOMES,
    meta: { requiresAuth: true },
    component: () => import('../../views/map/Outcomes.vue'),
    props: true,
  },

  /** View Lineage for Map's Outcomes */
  {
    path: '/client/:clientUuid/map/:curriculumMapUuid/lineage',
    name: 'map-lineage',
    meta: { requiresAuth: true },
    component: () => import('../../views/map/Lineage.vue'),
    props: true,
  },

  /** Map Preview (Outcomes Sunburst Chart) */
  {
    path: '/client/:clientUuid/map/:curriculumMapUuid/preview',
    name: 'map-preview',
    meta: { requiresAuth: true },
    component: () => import('../../views/map/Preview.vue'),
    props: true,
  },

  /** Export Map */
  {
    path: '/client/:clientUuid/map/:curriculumMapUuid/export',
    name: 'map-export',
    meta: { layout: 'blank', requiresAuth: true },
    component: () => import('../../views/map/Preview.vue'),
    props: true,
  },

  /** View Outcome */
  {
    path: '/client/:clientUuid/map/:curriculumMapUuid/outcome/:outcomeUuid',
    name: 'map-outcome',
    meta: { requiresAuth: true },
    component: () => import('../../views/map/Outcome.vue'),
    props: true,
  },
]
