import { apiClient, addAuthorizationHeader } from '@/services/api'

export default {
  searchMaps (query) {
    return addAuthorizationHeader(apiClient).get('/api/map', { params: query })
  },

  getMap (mapUuid) {
    return addAuthorizationHeader(apiClient).get('/api/map/' + mapUuid)
  },

  createMap (params) {
    return addAuthorizationHeader(apiClient).post('/api/map', params)
  },

  updateMap (mapUuid, params) {
    return addAuthorizationHeader(apiClient).put('/api/map/' + mapUuid, params)
  },

  cloneMap (mapUuid, clientUuid) {
    return addAuthorizationHeader(apiClient).post(
      `/api/map/${mapUuid}/clone-to/client/${clientUuid}`,
    )
  },

  deleteMap (mapUuid) {
    return addAuthorizationHeader(apiClient).delete('/api/map/' + mapUuid)
  },
}
