<template>
  <div class="container">
    <nav class="level mb-0">
      <p class="level-item has-text-centered">
        <img
          id="main-logo"
          :src="imgSrc"
          alt="Ease Platform"
          :width="imgWidth"
          :style="style"
        >
      </p>
    </nav>
    <div class="section pt-0">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Unauthenticated',
  computed: {
    isWideLogo: function () {
      return this.$route.name === 'deeplinks'
    },
    imgSrc: function () {
      return this.isWideLogo
        ? require('../assets/skillway_logo_wide.png')
        : require('../assets/skillways-logo2.png')
    },
    imgWidth: function () {
      return this.isWideLogo ? 150 : 80
    },
    style: function () {
      const marginTop = this.isWideLogo ? '5' : '20'
      return 'cursor:pointer;margin-top:' + marginTop + 'px;'
    },
  },
}
</script>
