/**
 * Deep value
 *
 * Get or set a property on an object using an arbitrary path on the object,
 * providing the path as a string. Useful when the path is a variable, as with
 * getters and setters.
 *
 * For example, deepValue.get(foo, 'bar.bat.baz') returns the value of
 * 'foo.bar.bat.baz'. Whereas deepValue.set(foo, 'bar.bat.baz', 5) sets
 * 'foo.bar.bat.baz = 5'
 */
export default {
  get (obj, path) {
    return path.split('.').reduce((a, v) => a[v], obj)
  },

  set (obj, path, value) {
    const a = path.split('.')
    let o = obj
    while (a.length - 1) {
      const n = a.shift()

      if (!(n in o)) o[n] = {}
      o = o[n]
    }

    o[a[0]] = value
  },
}
