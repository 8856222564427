// Auth
export const SET_REFRESH_TOKEN = 'SET_REFRESH_TOKEN'
export const SET_TOKEN = 'SET_TOKEN'

// Client
export const SET_CLIENT = 'SET_CLIENT'
export const SET_CLIENT_ASSIGNMENTS = 'SET_CLIENT_ASSIGNMENTS'
export const SET_CLIENT_DEPLOYMENTS = 'SET_CLIENT_DEPLOYMENTS'
export const SET_CLIENT_LEARNING_PATHS = 'SET_CLIENT_LEARNING_PATHS'
export const SET_CLIENT_LOADED = 'SET_CLIENT_LOADED'
export const SET_CLIENT_LOADING = 'SET_CLIENT_LOADING'
export const SET_CLIENT_LTI_LINKS = 'SET_CLIENT_LTI_LINKS'
export const SET_CLIENT_LTI_TOOLS = 'SET_CLIENT_LTI_TOOLS'
export const SET_CLIENT_TAG_CATEGORIES = 'SET_CLIENT_TAG_CATEGORIES'
export const SET_CLIENT_TAGS = 'SET_CLIENT_TAGS'
export const SET_CLIENT_THEMES = 'SET_CLIENT_THEMES'
export const SET_CLIENT_USAGE = 'SET_CLIENT_USAGE'
export const SET_CURRENT_CLIENT_UUID = 'SET_CURRENT_CLIENT_UUID'

// Learning path
export const ADD_PAGE = 'ADD_PAGE'
export const SET_CURRENT_LEARNING_PATH_MAP = 'SET_CURRENT_LEARNING_PATH_MAP'
export const SET_CURRENT_LEARNING_PATH_UUID = 'SET_CURRENT_LEARNING_PATH_UUID'
export const SET_LEARNING_PATH = 'SET_LEARNING_PATH'
export const SET_LEARNING_PATH_MAP_CONNECTIONS = 'SET_LEARNING_PATH_MAP_CONNECTIONS'
export const SET_LEARNING_PATH_MAP_REVERSION_CONNECTIONS = 'SET_LEARNING_PATH_MAP_REVERSION_CONNECTIONS'
export const SET_LEARNING_PATHS_LOADED = 'SET_LEARNING_PATHS_LOADED'
export const SET_RECENT_LEARNING_PATHS = 'SET_RECENT_LEARNING_PATHS'
export const SET_SHOW_LEARNING_PATH_NAVIGATION = 'SET_SHOW_LEARNING_PATH_NAVIGATION'
export const UPDATE_PAGE = 'UPDATE_PAGE'

// Curriculum map
export const ADD_CURRICULUM_MAP = 'ADD_CURRICULUM_MAP'
export const REMOVE_OUTCOME_FROM_CURRENT_CURRICULUM_MAP = 'REMOVE_OUTCOME_FROM_CURRENT_CURRICULUM_MAP'
export const SET_CLIENT_MAPS = 'SET_CLIENT_MAPS'
export const SET_CURRENT_CURRICULUM_MAP = 'SET_CURRENT_CURRICULUM_MAP'
export const SET_CURRENT_CURRICULUM_MAP_UUID = 'SET_CURRENT_CURRICULUM_MAP_UUID'
export const SET_CURRENT_OUTCOME = 'SET_CURRENT_OUTCOME'
export const SET_OUTCOME_CREATION_SUCCESSFUL = 'SET_OUTCOME_CREATION_SUCCESSFUL'
export const SET_RECENT_CURRICULUM_MAPS = 'SET_RECENT_CURRICULUM_MAPS'
export const UPSERT_OUTCOME_INTO_CURRENT_CURRICULUM_MAP = 'UPSERT_OUTCOME_INTO_CURRENT_CURRICULUM_MAP'

// Activity
export const SET_CURRENT_ACTIVITY = 'SET_CURRENT_ACTIVITY'
export const SET_CURRENT_REVERSION_ACTIVITY = 'SET_CURRENT_REVERSION_ACTIVITY'
export const SET_OPTION = 'SET_OPTION'
export const UPSERT_TO_ACTIVITY_CACHE = 'UPSERT_TO_ACTIVITY_CACHE'
export const SET_ACTIVITY_SAVE_SUCCESSFUL = 'SET_ACTIVITY_SAVE_SUCCESSFUL'

// Rubric
export const SET_CURRENT_REVERSION_RUBRIC = 'SET_CURRENT_REVERSION_RUBRIC'
export const SET_CURRENT_REVERSION_RUBRIC_OUTCOME_ACTIVITY_CRITERIA = 'SET_CURRENT_REVERSION_RUBRIC_OUTCOME_ACTIVITY_CRITERIA'
export const SET_CURRENT_RUBRIC = 'SET_CURRENT_RUBRIC'
export const SET_CURRENT_RUBRIC_STATE_ONLY = 'SET_CURRENT_RUBRIC_STATE_ONLY'
export const SET_CURRENT_RUBRIC_MAP = 'SET_CURRENT_RUBRIC_MAP'
export const SET_CURRENT_RUBRIC_OUTCOME_ACTIVITY_CRITERIA = 'SET_CURRENT_RUBRIC_OUTCOME_ACTIVITY_CRITERIA'
export const SET_DRAFT_CHANGES_OUTCOME_ACTIVITY_CRITERIA = 'SET_DRAFT_CHANGES_OUTCOME_ACTIVITY_CRITERIA'
export const SET_RUBRIC_MAP_CONNECTIONS = 'SET_RUBRIC_MAP_CONNECTIONS'
export const SET_RUBRIC_MAP_REVERSION_CONNECTIONS = 'SET_RUBRIC_MAP_REVERSION_CONNECTIONS'

// Activity L8y Items
export const SET_ACTIVITY_L8Y_ITEMS_MAP_CONNECTIONS = 'SET_ACTIVITY_L8Y_ITEMS_MAP_CONNECTIONS'
export const SET_CURRENT_OUTCOME_ACTIVITY_ACTIVITY_L8Y_ITEMS = 'SET_CURRENT_OUTCOME_ACTIVITY_ACTIVITY_L8Y_ITEMS'
export const SET_CURRENT_REVERSION_OUTCOME_ACTIVITY_ACTIVITY_L8Y_ITEMS = 'SET_CURRENT_REVERSION_OUTCOME_ACTIVITY_ACTIVITY_L8Y_ITEMS'
export const SET_DRAFT_CHANGES_OUTCOME_ACTIVITY_ACTIVITY_L8Y_ITEMS = 'SET_DRAFT_CHANGES_OUTCOME_ACTIVITY_ACTIVITY_L8Y_ITEMS'
export const SET_ACTIVITY_L8Y_ITEMS = 'SET_ACTIVITY_L8Y_ITEMS'
export const ADD_ACTIVITY_L8Y_ITEM = 'ADD_ACTIVITY_L8Y_ITEM'
export const REMOVE_ACTIVITY_L8Y_ITEM = 'REMOVE_ACTIVITY_L8Y_ITEM'

// LTI link
export const SET_CURRENT_LTI_LINK_ID = 'SET_CURRENT_LTI_LINK_ID'

// Tag
export const SET_TAGS_BY_CATEGORIES = 'SET_TAGS_BY_CATEGORIES'
export const SET_TAGS_LOADED = 'SET_TAGS_LOADED'

// Theme
export const SET_THEMES_LOADED = 'SET_THEMES_LOADED'

// Non-entity
export const ADD_PENDING_FILE_UPLOAD = 'ADD_PENDING_FILE_UPLOAD'
export const SET_DASHBOARD_LOADED = 'SET_DASHBOARD_LOADED'
export const SET_DRAFT_CHANGES = 'SET_DRAFT_CHANGES'
export const SET_LIST_VIEW = 'SET_LIST_VIEW'
export const SET_RELOADED = 'SET_RELOADED'
export const SET_USAGE_LOADED = 'SET_USAGE_LOADED'
export const SET_SHOW_DRAFT_CHANGES_MODAL = 'SET_SHOW_DRAFT_CHANGES_MODAL'
