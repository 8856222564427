<template>
  <div
    :class="dropdownClass"
    @click="toggleDropdown()"
  >
    <div class="dropdown-trigger">
      <button
        :class="getButtonClass()"
        aria-haspopup="true"
        aria-controls="dropdown-menu"
      >
        <span>{{ selectedClientName }}</span>
        <span class="icon is-small">
          <i
            class="fas fa-angle-down"
            aria-hidden="true"
          />
        </span>
      </button>
    </div>
    <div
      id="dropdown-menu"
      class="dropdown-menu"
      role="menu"
    >
      <div
        v-if="(typeof nestedClientAssignments) !== 'undefined'"
        class="dropdown-content is-scrollable"
      >
        <a
          v-if="!switchClient"
          class="dropdown-item"
          @click="changeAccount('All')"
        >All</a>
        <hr class="dropdown-divider">
        <template
          v-for="clientAssignment in nestedClientAssignments"
        >
          <a
            :key="clientAssignment.client.uuid"
            class="dropdown-item"
            @click="changeAccount(clientAssignment.client.uuid)"
          >{{ clientAssignment.client.name }}</a>
          <template
            v-for="clientAssignmentChild in clientAssignment.children"
          >
            <a
              :key="clientAssignmentChild.client.uuid"
              class="dropdown-item is-size-7"
              @click="changeAccount(clientAssignmentChild.client.uuid)"
            >
              <span class="ml-4">{{ clientAssignmentChild.client.name }}</span>
            </a>
          </template>
        </template>
        <template v-if="user.isAdmin && switchClient">
          <hr class="dropdown-divider">
          <a
            class="dropdown-item"
            @click="createAccount()"
          >+ New</a>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  name: 'DropdownAccount',
  props: {
    switchClient: {
      type: Boolean,
      default: true,
    },
    selected: {
      type: [Number, String],
      required: false,
      default: undefined,
    },
    size: {
      type: String,
      default: '',
    },
    fromAdmin: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      dropdownClass: 'dropdown',
    }
  },
  computed: {
    ...mapGetters([
      'clientAssignmentsSortedByClientName',
      'user',
    ]),
    ...mapState({
      clientUuid: (state) => state.clients.currentClientUuid,
    }),
    selectedClientName: function () {
      if (typeof this.selected !== 'undefined') {
        if (this.selected !== 'All') {
          const clientAssignment = this.clientAssignmentsSortedByClientName.find(
            aClientAssignment => aClientAssignment.client.uuid === this.selected,
          )

          return clientAssignment.client.name
        }

        return 'All'
      }

      if (typeof this.clientUuid !== 'undefined') {
        const clientAssignment = this.clientAssignmentsSortedByClientName.find(
          clientAssignment => {
            return clientAssignment.client.uuid === this.clientUuid
          },
        )

        return clientAssignment.client.name
      }

      return 'All'
    },
    nestedClientAssignments: function () {
      const parents = this.clientAssignmentsSortedByClientName.filter(
        clientAssignment => clientAssignment.client.parent === null,
      )

      const nested = parents.map((parentAssignment) => {
        parentAssignment.children = this
          .clientAssignmentsSortedByClientName
          .filter((clientAssignment) => {
            return clientAssignment.client.parent !== null
              ? clientAssignment.client.parent.uuid === parentAssignment.client.uuid
              : false
          })

        return parentAssignment
      })

      // Ensure all accounts are accessed and represented in array.
      if (nested.length < this.clientAssignmentsSortedByClientName.length) {
        this
          .clientAssignmentsSortedByClientName
          .forEach((clientAssignment) => {
            const isAlreadyListed = this.isAlreadyListed(clientAssignment, nested)

            if (!isAlreadyListed) {
              nested.push(clientAssignment)
            }
          })
      }

      return nested
    },
  },
  methods: {
    isAlreadyListed (clientAssignment, nestedClientAssignments) {
      return nestedClientAssignments.some((parentAssignment) => {
        return parentAssignment.client.uuid === clientAssignment.client.uuid ||
          this.isAlreadyListedInChildren(clientAssignment, parentAssignment)
      })
    },
    isAlreadyListedInChildren (childAssignment, parentAssignment) {
      if (
        typeof parentAssignment.children !== 'undefined' &&
        Array.isArray(parentAssignment.children)
      ) {
        return parentAssignment.children.some(child =>
          child.client.uuid === childAssignment.client.uuid,
        )
      }

      return false
    },
    changeAccount (clientUuid) {
      if (this.switchClient) {
        this.$router.push({
          name: 'Dashboard',
          params: { clientUuid: clientUuid },
        })
      } else {
        // Pass back the clientUuid.
        this.$emit('filterClient', clientUuid)
      }
    },
    createAccount () {
      this.$router.push({
        name: 'NewClient',
        params: { clientUuid: this.clientUuid },
      })
    },
    toggleDropdown () {
      if (this.dropdownClass === 'dropdown') {
        this.dropdownClass = 'dropdown is-active'
      } else {
        this.dropdownClass = 'dropdown'
      }
    },
    getButtonClass () {
      return `button ${this.size}`
    },
  },
}
</script>

<style scoped>
.is-scrollable {
  overflow-y: scroll;
  max-height: 300px
}
</style>
