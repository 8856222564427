import {
  ADD_CURRICULUM_MAP,
  REMOVE_OUTCOME_FROM_CURRENT_CURRICULUM_MAP,
  SET_CLIENT_MAPS,
  SET_CURRENT_CURRICULUM_MAP,
  SET_CURRENT_CURRICULUM_MAP_UUID,
  SET_CURRENT_OUTCOME,
  SET_OUTCOME_CREATION_SUCCESSFUL,
  SET_RECENT_CURRICULUM_MAPS,
  UPSERT_OUTCOME_INTO_CURRENT_CURRICULUM_MAP,
} from '@/store/mutation-types.js'
import api from '@/services/api.js'
import getMap from '@/functions/getMap'
import deepClone from '@/functions/helpers/deepClone'

export const curriculumMap = {
  state: {
    /** @type {CurriculumMap|undefined} */
    curriculumMap: undefined,

    /** @type {UUIDv7|undefined} */
    currentCurriculumMapUuid: undefined,

    /**
     * @type {Boolean} outcomeCreationSuccessful
     *                Used to trigger error modal in the component calling the
     *                outcome creation action if/when outcome creation fails in
     *                the API.
     */
    outcomeCreationSuccessful: true,

    /** @type {OutcomeSerializedMap|undefined} */
    outcome: undefined,

    /** @type {CurriculumMap[]|undefined} */
    clientMaps: undefined,

    recentMaps: [],
  },

  mutations: {
    [SET_RECENT_CURRICULUM_MAPS] (state, recentMaps) {
      state.recentMaps = recentMaps
    },

    [SET_CLIENT_MAPS] (state, list) {
      state.clientMaps = list
    },

    /**
     * @param state
     * @param {UUIDv7} curriculumMapUuid
     */
    [SET_CURRENT_CURRICULUM_MAP_UUID] (state, curriculumMapUuid) {
      state.currentCurriculumMapUuid = curriculumMapUuid
    },

    /**
     * @param state
     * @param {CurriculumMap} curriculumMap
     */
    [SET_CURRENT_CURRICULUM_MAP] (state, curriculumMap) {
      state.curriculumMap = curriculumMap
    },

    /**
     * @param state
     * @param {CurriculumMap} curriculumMap
     */
    [ADD_CURRICULUM_MAP] (state, curriculumMap) {
      state.clientMaps.push(curriculumMap)
    },

    /**
     * @param state
     * @param {OutcomeSerializedMap} outcome
     */
    [SET_CURRENT_OUTCOME] (state, outcome) {
      state.outcome = outcome
    },

    /**
     * @param state
     * @param {OutcomeScalars} outcomeToRemove
     */
    [REMOVE_OUTCOME_FROM_CURRENT_CURRICULUM_MAP] (state, outcomeToRemove) {
      const level = state.curriculumMap.outcomes.find(
        anOutcomeLevel => anOutcomeLevel.level.uuid === outcomeToRemove.level.uuid,
      )

      const existingOutcomeIndex = level.outcomes.findIndex(
        anOutcome => anOutcome.temporaryLocalStateUuid === outcomeToRemove.temporaryLocalStateUuid,
      )

      level.outcomes.splice(existingOutcomeIndex, 1)
    },

    /**
     * @param state
     * @param {OutcomeEntitySimple} outcomeBeingUpserted
     */
    [UPSERT_OUTCOME_INTO_CURRENT_CURRICULUM_MAP] (state, outcomeBeingUpserted) {
      const level = state.curriculumMap.outcomes.find(
        anOutcomeLevel => anOutcomeLevel.level.uuid === outcomeBeingUpserted.level.uuid,
      )

      const existingOutcome = level.outcomes.find(
        anOutcome => anOutcome.uuid === outcomeBeingUpserted.uuid,
      )

      if (existingOutcome) {
        // Update the outcome.
        Object.assign(existingOutcome, outcomeBeingUpserted)
      } else {
        // The outcome isn't in the state yet.
        level.outcomes.push(outcomeBeingUpserted)
      }

      // Special handling for lineage and level 1 outcomes.
      if (outcomeBeingUpserted.level.depth === 1) {
        const existingOutcomeLineage = state.curriculumMap.lineage.find(
          anOutcome => anOutcome.uuid === outcomeBeingUpserted.uuid,
        )

        if (existingOutcomeLineage) {
          // Update the outcome.
          Object.assign(existingOutcome, {
            legend: outcomeBeingUpserted.legend,
            name: outcomeBeingUpserted.name,
          })
        } else {
          // The outcome isn't in the lineage state yet.
          state.curriculumMap.lineage.push({
            children: [],
            id: outcomeBeingUpserted?.id,
            legend: outcomeBeingUpserted.legend,
            level: outcomeBeingUpserted.level.depth,
            name: outcomeBeingUpserted.name,
            uuid: outcomeBeingUpserted.uuid,
          })
        }
      }
    },

    /**
     * @param state
     * @param {Boolean} outcomeCreationStatus
     */
    [SET_OUTCOME_CREATION_SUCCESSFUL] (state, outcomeCreationStatus) {
      state.outcomeCreationSuccessful = outcomeCreationStatus
    },
  },

  actions: {
    async getClientMaps ({ commit }, clientUuid) {
      const resp = await api.getClientMaps(clientUuid)
      const list = resp.data.data
      commit(SET_CLIENT_MAPS, list)

      return true
    },

    /**
     * Set Current Curriculum Map
     *
     * @param commit
     * @param {UUIDv7} curriculumMapUuid
     * @returns {Promise<void>}
     */
    async setCurrentCurriculumMap ({ commit }, curriculumMapUuid) {
      commit(SET_CURRENT_CURRICULUM_MAP_UUID, curriculumMapUuid)
      const curriculumMapApiResponse = await getMap.call(curriculumMapUuid)

      if (curriculumMapApiResponse.data?.data?.map !== undefined) {
        /** @var {CurriculumMap} curriculumMap */
        const curriculumMap = curriculumMapApiResponse.data.data.map
        commit(SET_CURRENT_CURRICULUM_MAP, curriculumMap)
      }
    },

    /**
     * Update Map
     *
     * @param dispatch
     * @param commit
     * @param {CurriculumMap} map
     * @returns {Promise<void>}
     */
    async updateMap ({ dispatch, commit }, map) {
      const params = {
        client: map.client,
        levels: map.outcomeLevels,
        title: map.title,
      }

      await api.updateMap(map.uuid, params)
      commit(SET_CURRENT_CURRICULUM_MAP_UUID, map.uuid)
      dispatch('setCurrentCurriculumMap', map.uuid)
    },

    async deleteOutcome ({ dispatch, commit }, outcome) {
      commit(REMOVE_OUTCOME_FROM_CURRENT_CURRICULUM_MAP, outcome)

      await api.deleteOutcome(outcome.uuid)
    },

    /**
     * Create Outcome
     *
     * @param dispatch
     * @param commit
     * @param state
     * @param {OutcomeScalars} outcome
     */
    async createOutcome ({ dispatch, commit, state }, outcome) {
      /** @type {OutcomeScalars} */
      const outcomeBeingCreated = deepClone.call(outcome) // Detach from form.

      api.createOutcome(outcomeBeingCreated)
        .then(response => {
          commit(
            UPSERT_OUTCOME_INTO_CURRENT_CURRICULUM_MAP,
            response.data.data.outcome,
          )
        })
        .catch(() => {
          // The API was unable to create the outcome. Trigger the error modal.
          commit(SET_OUTCOME_CREATION_SUCCESSFUL, false)

          commit(
            REMOVE_OUTCOME_FROM_CURRENT_CURRICULUM_MAP,
            outcomeBeingCreated,
          )

          return true
        })
    },
  },

  getters: {
    /** @return {CurriculumMap[]} */
    recentMaps: state => state.recentMaps,

    /** @return {CurriculumMap[]} */
    currentClientMaps: state => state.clientMaps,

    boundLearningPaths: (_state, getters) => {
      return getters.currentClientAvailableLearningPaths.filter(
        availableLearningPath =>
          getters.currentCurriculumMap.learningPaths.includes(
            availableLearningPath.uuid,
          ),
      )
    },

    /** @return {OutcomeSerializedMap|undefined} */
    currentOutcome: (state) => {
      return state.outcome
    },

    /** @return {CurriculumMap|undefined} */
    currentCurriculumMap: (state) => {
      return state.curriculumMap
    },

    /** @returns {OutcomeSerializedMap[]} */
    curriculumMapAllOutcomes: (_state, getters) => {
      if (getters.currentCurriculumMap === undefined) {
        return []
      }

      // Get all outcomes from the various levels in the map.
      // noinspection JSValidateTypes Fix this by fixing API return type on outcome creation.
      return getters.currentCurriculumMap.outcomes.map(
        outcome => outcome.outcomes,
      ).flat()
    },

    /** @returns {OutcomeSerializedMap[]} */
    curriculumMapUnusedOutcomes: (state, getters) => {
      if (getters.currentCurriculumMap === undefined) return []
      return getters.curriculumMapAllOutcomes.filter(
        outcome => outcome.level.depth !== 1 && !outcome.hasParent,
      )
    },

    /** @returns {(any) => OutcomeSerializedMap} */
    getOutcomeByUuid: (_state, getters) => (outcomeUuid) => {
      return getters
        .curriculumMapAllOutcomes
        .find(outcome => outcome.uuid === outcomeUuid)
    },

    /** @return {LineageLevel[]} */
    lineage: (_state, getters) => {
      return getters.currentCurriculumMap?.lineage ?? []
    },

    /** @return {Boolean} */
    outcomeCreationSuccessful: (state) => {
      return state.outcomeCreationSuccessful
    },
  },
}
