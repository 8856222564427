import { apiClient, addAuthorizationHeader } from '@/services/api'

/**
 * Page API Endpoints
 */
export default {
  createPage (params) {
    return addAuthorizationHeader(apiClient).post('/api/page', params)
  },

  updatePage (pageId, params) {
    return addAuthorizationHeader(apiClient).put('/api/page/' + pageId, params)
  },

  deletePage (pageId) {
    return addAuthorizationHeader(apiClient).delete('/api/page/' + pageId)
  },

  movePage (movingPageId, referencePageId) {
    return addAuthorizationHeader(apiClient).put(
      `/api/page/${movingPageId}/move-to/${referencePageId}`,
    )
  },
}
