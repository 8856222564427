import { castIntegerRouteParams } from '@/router'

/**
 * Page Routes
 *
 * @return {RouteRecordSingleView[]}
 */
export default [
  /** View Module Overview Page */
  {
    path: '/client/:clientUuid/learning-path/:learningPathUuid/module/:moduleId/overview-page',
    name: 'learning-path-pages-module-page',
    meta: { requiresAuth: true },
    component: () => import('../../views/path/pages/ModulePage.vue'),
    props: castIntegerRouteParams,
  },

  /** View Page */
  {
    path: '/client/:clientUuid/learning-path/:learningPathUuid/module/:moduleId/page/:pageId',
    name: 'learning-path-pages-page',
    meta: { requiresAuth: true },
    component: () => import('../../views/path/pages/Page.vue'),
    props: castIntegerRouteParams,
  },

  /** Create Page */
  {
    path: '/client/:clientUuid/learning-path/:learningPathUuid/module/:moduleId/create-page',
    name: 'learning-path-pages-page-create',
    meta: { requiresAuth: true },
    component: () => import('../../views/path/pages/PageCreate.vue'),
  },
]
