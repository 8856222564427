import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Authenticated from './layouts/Authenticated.vue'
import Blank from './layouts/Blank.vue'
import Unauthenticated from './layouts/Unauthenticated.vue'
import Buefy from 'buefy'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'

/* CSS */
import '@fortawesome/fontawesome-free/css/all.min.css'
import './assets/main.scss'

Vue.use(Buefy, {
  defaultIconPack: 'fas',
})

// noinspection JSCheckFunctionSignatures
Vue.use(Vuelidate)
Vue.use(VueMask)

Vue.component('AuthenticatedLayout', Authenticated)
Vue.component('UnauthenticatedLayout', Unauthenticated)
Vue.component('BlankLayout', Blank)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
