<template>
  <div class="white">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Blank',
}
</script>

<style scoped>
.white {
  height: 100vh;
}
</style>
