import { apiClient, addAuthorizationHeader, apiClientForm } from '@/services/api'

/**
 * OutcomeActivity ActivityL8yItem (Question Mapping) API Endpoints
 */
export default {
  /**
   * Upsert Outcome Activity Activity L8y Items
   *
   * @param {SerializedOutcomeActivityCriteria} outcomeActivityActivityL8yItems
   * @returns {*}
   */
  upsertOutcomeActivityActivityL8yItems (outcomeActivityActivityL8yItems) {
    return addAuthorizationHeader(apiClient).put(
      '/api/outcome-activity-activity-l8y-items',
      outcomeActivityActivityL8yItems,
    )
  },

  /**
   * Get Outcome Activity Activity L8y Items
   *
   * @param {UUIDv7} activityUuid
   * @returns {
   *    Promise<{data:{data:{outcomeActivityActivityL8yItems: SerializedOutcomeActivityActivityL8yItems}}}>
   *  }
   */
  getOutcomeActivityActivityL8yItems (activityUuid) {
    return addAuthorizationHeader(apiClient).get(
      '/api/activity/' + activityUuid + '/outcome-activity-activity-l8y-items',
    )
  },

  /**
   * Upload Question Mapping
   *
   * @param {UUIDv7} activityUuid
   * @param {FormData} file
   * @returns {*}
   * @type {FileUploadEndpoint}
   */
  uploadQuestionMapping (activityUuid, file) {
    return addAuthorizationHeader(apiClientForm).post(
      '/api/activity/' + activityUuid + '/question-mapping',
      file,
    )
  },

  /**
   * Upload QTI
   *
   * @param {UUIDv7} activityUuid
   * @param {FormData} file
   * @returns {*}
   * @type {FileUploadEndpoint}
   */
  uploadQti (activityUuid, file) {
    return addAuthorizationHeader(apiClientForm).post(
      '/api/activity/' + activityUuid + '/qti',
      file,
    )
  },
}
