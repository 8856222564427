/**
 * Deep Clone
 */
export default {
  /**
   * @template T
   * @param {T} obj
   * @returns {T}
   */
  call (obj) {
    // TODO: Add null + primitive types here and return as is.
    if (obj === undefined) {
      return undefined
    }

    let out

    if (Array.isArray(obj)) {
      out = []
      for (let index = 0; index < obj.length; ++index) {
        const subArray = obj[index]
        out.push((subArray === null)
          ? subArray : (subArray instanceof Date)
            ? new Date(subArray.valueOf()) : (typeof subArray === 'object')
              ? this.call(subArray) : subArray)
      }
    } else {
      out = {}
      for (const key in obj) {
        const subObject = obj[key]
        out[key] = subObject === null
          ? subObject : subObject instanceof Date
            ? new Date(subObject.valueOf()) : (typeof subObject === 'object')
              ? this.call(subObject) : subObject
      }
    }

    return out
  },
}
