import { SET_CURRENT_LTI_LINK_ID } from '@/store/mutation-types.js'

export const ltiLink = {
  state: {
    currentLtiLinkId: Number(localStorage.getItem('currentLtiLinkId')) || undefined,
  },
  mutations: {
    [SET_CURRENT_LTI_LINK_ID] (state, id) {
      state.currentLtiLinkId = id
      localStorage.setItem('currentLtiLinkId', id)
    },
  },
  actions: {
    setCurrentLtiLinkId ({ commit }, id) {
      commit(SET_CURRENT_LTI_LINK_ID, id)
    },
  },
  getters: {
    currentLtiLink: (state, getters) => {
      if (
        typeof state.currentLtiLinkId !== 'undefined' &&
        typeof getters.currentLearningPath !== 'undefined' &&
        typeof getters.currentLearningPath.ltiLinks !== 'undefined'
      ) {
        const learningPath = getters.currentLearningPath.ltiLinks.find(
          link => Number(link.id) === Number(state.currentLtiLinkId),
        )

        if (typeof learningPath !== 'undefined') {
          return learningPath
        }
      }

      return undefined
    },
  },
}
