import decode from 'jwt-decode'
import api from '@/services/api.js'
import {
  SET_CLIENT_ASSIGNMENTS,
  SET_CURRENT_CLIENT_UUID,
  SET_REFRESH_TOKEN,
  SET_TOKEN,
} from '@/store/mutation-types.js'

export const auth = {
  state: {
    clientAssignments: [],
    refreshToken: api.getFromLocalStorage('refreshToken', ''),
    token: api.getFromLocalStorage('token', ''),
  },
  mutations: {
    [SET_CLIENT_ASSIGNMENTS] (state, clientAssignments) {
      state.clientAssignments = clientAssignments
    },

    [SET_REFRESH_TOKEN] (state, refreshToken) {
      state.refreshToken = refreshToken
    },

    [SET_TOKEN] (state, token) {
      state.token = token
    },
  },
  actions: {
    async getClientAssignments ({ commit }, userId) {
      const clientAssignments = (await api.getUser(userId)).data.user.clientAssignments
      commit(SET_CLIENT_ASSIGNMENTS, clientAssignments)
      return clientAssignments
    },

    async login ({ commit, dispatch }, params) {
      try {
        // Get the token and save it to the state.
        const tokenInfo = (await api.getAccessToken(params)).data
        commit(SET_REFRESH_TOKEN, tokenInfo.refreshToken)
        commit(SET_TOKEN, tokenInfo.token)

        // Save it to localStorage as well.
        localStorage.setItem('refreshToken', tokenInfo.refreshToken)
        localStorage.setItem('token', tokenInfo.token)

        // Load the client assignments.
        const user = decode(tokenInfo.token).data.user
        await dispatch('getClientAssignments', user.id)

        return user
      } catch (e) {
        localStorage.clear()
        throw e
      }
    },

    async loginWithLocalStorageToken ({ commit, dispatch }) {
      const refreshToken = localStorage.getItem('refreshToken')
      const token = localStorage.getItem('token')
      commit(SET_REFRESH_TOKEN, refreshToken)
      commit(SET_TOKEN, token)
      const user = decode(token).data.user
      await dispatch('getClientAssignments', user.id)
    },

    async loginWithQueryStringToken ({ commit, dispatch }) {
      const urlSearchParams = new URLSearchParams(location.search)

      // Check if there is a token in the query string.
      if (!urlSearchParams.has('token')) {
        return undefined
      }

      // Extract the token and save it to state.
      const token = urlSearchParams.get('token')
      commit(SET_TOKEN, token)
      commit(SET_REFRESH_TOKEN, token)

      // Save it to localStorage as well.
      localStorage.setItem('refreshToken', token)
      localStorage.setItem('token', token)

      // Load the client assignments.
      await dispatch(
        'getClientAssignments',
        decode(token).data.user.id,
      )
    },

    logout ({ commit }) {
      commit(SET_CLIENT_ASSIGNMENTS, [])
      commit(SET_CURRENT_CLIENT_UUID, undefined)
      commit(SET_REFRESH_TOKEN, '')
      commit(SET_TOKEN, '')
      localStorage.clear()
    },

    async refreshAuth ({ commit, state }) {
      try {
        const params = { refreshToken: state.refreshToken }
        const resp = await api.refreshAccessToken(params)
        const token = resp && resp.data && resp.data.token
        const refreshToken = resp && resp.data && resp.data.refreshToken
        commit(SET_TOKEN, token)
        commit(SET_REFRESH_TOKEN, refreshToken)
        localStorage.setItem('token', token)
        localStorage.setItem('refreshToken', refreshToken)
        return resp
      } catch (e) {
        localStorage.clear()
        throw e.response
      }
    },
  },
  getters: {
    availableClients: (state) => state
      .clientAssignments
      .map((clientAssignment) => clientAssignment.client)
      .sort((a, b) => a.name.localeCompare(b.name)),

    clientAssignmentsSortedByClientName: (state) =>
      [...state.clientAssignments].sort((a, b) => a.client.name.localeCompare(b.client.name)),

    isAdmin: (_state, getters) => typeof getters.user !== 'undefined'
      ? getters.user.isAdmin
      : false,

    isSuperAdmin: (_state, getters) => typeof getters.user !== 'undefined'
      ? getters.user.isSuperAdmin
      : false,

    isLoggedIn: (state) => state.token !== '',

    user: (state) => decode(state.token)?.data?.user,
  },
}
