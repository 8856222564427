import { apiClient, addAuthorizationHeader } from '@/services/api'

/**
 * Rubric API Endpoints
 */
export default {
  /**
   * Get Rubric
   *
   * @param {UUIDv7} rubricUuid
   * @returns {Promise<{data:{data:{rubric: Rubric}}}>}
   */
  getRubric (rubricUuid) {
    return addAuthorizationHeader(apiClient).get('/api/rubric/' + rubricUuid)
  },

  /**
   * Upsert Rubric
   *
   * @param {Rubric} rubric
   * @returns {Promise}
   */
  upsertRubric (rubric) {
    return addAuthorizationHeader(apiClient).put(
      '/api/rubric',
      rubric,
      { validateStatus: null },
    )
  },

  /**
   * Delete Rubric
   *
   * @param {UUIDv7} rubricUuid
   * @returns {Promise}
   */
  deleteRubric (rubricUuid) {
    return addAuthorizationHeader(apiClient).delete('/api/rubric/' + rubricUuid)
  },

  /**
   * Upsert Outcome Activity Criteria
   *
   * @param {SerializedOutcomeActivityCriteria} outcomeActivityCriteria
   * @returns {*}
   */
  upsertRubricOutcomeActivityCriteria (outcomeActivityCriteria) {
    return addAuthorizationHeader(apiClient).put(
      '/api/outcome-activity-criteria',
      outcomeActivityCriteria,
      { validateStatus: null },
    )
  },

  /**
   * Get Outcome Activity Criteria
   *
   * @param {UUIDv7} activityUuid
   * @returns {
   *    Promise<{data:{data:{outcomeActivityCriteria: SerializedOutcomeActivityCriteria}}}>
   *  }
   */
  getRubricOutcomeActivityCriteria (activityUuid) {
    return addAuthorizationHeader(apiClient).get(
      '/api/activity/' + activityUuid + '/outcome-activity-criteria',
    )
  },
}
