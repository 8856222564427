<template>
  <b-dropdown
    position="is-bottom-left"
    aria-role="list"
  >
    <avatar
      slot="trigger"
      :username="name"
      background-color="#1b75bb"
      color="#fff"
      :size="32"
      style="cursor:pointer;margin-left:0;padding-left:0"
    />
    <div class="py-4 px-4">
      <span class="is-size-7 has-text-grey-light">Logged in as</span><br>
      <b>{{ name }}</b><br>
      <span>{{ role }}</span>
    </div>
    <hr class="navbar-divider">
    <router-link :to="getRoute('AdminLtiUsers')">
      <b-dropdown-item
        v-if="user.isAdmin"
        aria-role="listitem"
      >
        Ease Admin
      </b-dropdown-item>
    </router-link>
    <router-link :to="getRoute('Profile')">
      <b-dropdown-item aria-role="listitem">
        My Profile
      </b-dropdown-item>
    </router-link>
    <router-link :to="getRoute('General')">
      <b-dropdown-item
        v-if="currentRoleCanEdit"
        aria-role="listitem"
      >
        Organization Settings
      </b-dropdown-item>
    </router-link>
    <hr class="navbar-divider">
    <a
      href="https://easeplatform.zendesk.com/hc/en-us"
      target="_blank"
      class="dropdown-item"
    >Support</a>
    <hr class="navbar-divider">
    <b-dropdown-item
      aria-role="listitem"
      @click="triggerLogout()"
    >
      Logout
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import Avatar from 'vue-avatar'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'DropdownUser',
  components: {
    Avatar,
  },
  computed: {
    ...mapGetters([
      'currentRoleCanEdit',
      'user',
    ]),
    ...mapState({
      currentClientUuid: (state) => state.clients.currentClientUuid,
    }),
    name: function () {
      return `${this.user.firstName} ${this.user.lastName}`
    },
    role: function () {
      if (this.user.isAdmin) {
        return 'Ease Admin'
      } else if (this.currentRoleCanEdit) {
        return 'Admin'
      } else {
        return 'Observer'
      }
    },
  },
  methods: {
    ...mapActions(['logout']),
    getRoute (name) {
      return {
        name: name,
        params: { clientUuid: this.currentClientUuid },
      }
    },
    async triggerLogout () {
      // noinspection ES6MissingAwait // this is not async
      this.logout()
      await this.$router.push('/login')
    },
  },
}
</script>
