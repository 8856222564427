import api from '@/services/api.js'
import {
  SET_ACTIVITY_SAVE_SUCCESSFUL,
} from '@/store/mutation-types'
import store from '../store'

/**
 * @todo Have the activity components provide their API endpoint in the state,
 *       which <Activity> calls, then remove this file/function.
 */
export default {
  call (type, id, params) {
    switch (type) {
      case 'assessment':
        return this.assessment(id, params)
      case 'l8y':
        return this.l8y(id, params)
      case 'media':
        return this.media(id, params)
      case 'video':
        return this.video(id, params)
      case 'fileUpload':
        return this.file(id, params)
      case 'quiz':
        return this.quiz(id, params)
      case 'survey':
        return this.survey(id, params)
      case 'discussion':
        return this.discussion(id, params)
      case 'lti1p3':
        return this.lti1p3(id, params)
      default:
        return this.lti(id, params)
    }
  },

  assessment (id, params) {
    return new Promise((resolve, reject) => {
      api.updateActivityAssessment(id, params)
        .then(resp => {
          resolve(resp)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  l8y (id, params) {
    return new Promise((resolve, reject) => {
      api.updateActivityL8y(id, params)
        .then(resp => {
          resolve(resp)
        })
        .catch(() => {
          // The API was unable to save the activity. Trigger the error modal.
          store.commit(SET_ACTIVITY_SAVE_SUCCESSFUL, false)

          return true
        })
    })
  },

  lti (id, params) {
    return new Promise((resolve, reject) => {
      api.updateActivityLti(id, params)
        .then(resp => {
          resolve(resp)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  lti1p3 (id, params) {
    return new Promise((resolve, reject) => {
      api.updateActivityLti1p3(id, params)
        .then(resp => {
          resolve(resp)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  media (id, params) {
    return new Promise((resolve, reject) => {
      api.updateActivityMedia(id, params)
        .then(resp => {
          resolve(resp)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  discussion (id, params) {
    return new Promise((resolve, reject) => {
      api.updateActivityDiscussion(id, params)
        .then(resp => {
          resolve(resp)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  quiz (id, params) {
    return new Promise((resolve, reject) => {
      api.updateActivityQuiz(id, params)
        .then(resp => {
          resolve(resp)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  survey (id, params) {
    return new Promise((resolve, reject) => {
      api.updateActivitySurvey(id, params)
        .then(resp => {
          resolve(resp)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  video (id, params) {
    return new Promise((resolve, reject) => {
      api.updateActivityVideo(id, params)
        .then(resp => {
          resolve(resp)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  file (id, params) {
    return new Promise((resolve, reject) => {
      api.updateActivityFile(id, params)
        .then(resp => {
          resolve(resp)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
}
