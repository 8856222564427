import {
  SET_ACTIVITY_L8Y_ITEMS,
  ADD_ACTIVITY_L8Y_ITEM,
  REMOVE_ACTIVITY_L8Y_ITEM,
  SET_CURRENT_OUTCOME_ACTIVITY_ACTIVITY_L8Y_ITEMS,
  SET_CURRENT_REVERSION_OUTCOME_ACTIVITY_ACTIVITY_L8Y_ITEMS,
  SET_DRAFT_CHANGES_OUTCOME_ACTIVITY_ACTIVITY_L8Y_ITEMS,
} from '@/store/mutation-types.js'
import deepClone from '@/functions/helpers/deepClone'

export const activityL8yItems = {
  /** @type {{
   *    currentActivityL8yItems: ActivityL8yItem[]|undefined,
   *    currentOutcomeActivityActivityL8yItems: SerializedOutcomeActivityActivityL8yItems,
   *    currentReversionOutcomeActivityActivityL8yItems: SerializedOutcomeActivityActivityL8yItems,
   *    draftChangesOutcomeActivityActivityL8yItems: Boolean
   *  }}
   */
  state: {
    currentActivityL8yItems: undefined,
    currentOutcomeActivityActivityL8yItems: undefined,
    currentReversionOutcomeActivityActivityL8yItems: undefined,
    draftChangesOutcomeActivityActivityL8yItems: false,
  },

  mutations: {
    /**
     * @param state
     * @param {ActivityL8yItem[]} activityL8yItems
     */
    [SET_ACTIVITY_L8Y_ITEMS] (
      state,
      activityL8yItems,
    ) {
      state.currentActivityL8yItems = deepClone.call(
        activityL8yItems,
      )
    },

    /**
     * @param state
     * @param {ActivityL8yItem} activityL8yItem
     */
    [ADD_ACTIVITY_L8Y_ITEM] (state, activityL8yItem) {
      state.currentActivityL8yItems.push(activityL8yItem)
    },

    /**
     * @param state
     * @param {ActivityL8yItem} activityL8yItem
     */
    [REMOVE_ACTIVITY_L8Y_ITEM] (state, activityL8yItem) {
      state.currentActivityL8yItems = state.currentActivityL8yItems.filter(
        anActivityL8yItem => anActivityL8yItem.uuid !== activityL8yItem.uuid,
      )
    },

    /**
     * @param state
     * @param {Boolean} draftChangesOutcomeActivityActivityL8yItems
     */
    [SET_DRAFT_CHANGES_OUTCOME_ACTIVITY_ACTIVITY_L8Y_ITEMS] (
      state,
      draftChangesOutcomeActivityActivityL8yItems,
    ) {
      state.draftChangesOutcomeActivityActivityL8yItems = draftChangesOutcomeActivityActivityL8yItems
    },

    /**
     * @param state
     * @param {SerializedOutcomeActivityActivityL8yItems} outcomeActivityActivityL8yItems
     */
    [SET_CURRENT_OUTCOME_ACTIVITY_ACTIVITY_L8Y_ITEMS] (
      state,
      outcomeActivityActivityL8yItems,
    ) {
      state.currentOutcomeActivityActivityL8yItems = deepClone.call(
        outcomeActivityActivityL8yItems,
      )
    },

    /**
     * @param state
     * @param {SerializedOutcomeActivityActivityL8yItems} outcomeActivityActivityL8yItems
     */
    [SET_CURRENT_REVERSION_OUTCOME_ACTIVITY_ACTIVITY_L8Y_ITEMS] (
      state,
      outcomeActivityActivityL8yItems,
    ) {
      state.currentReversionOutcomeActivityActivityL8yItems = deepClone.call(
        outcomeActivityActivityL8yItems,
      )
    },
  },

  getters: {
    currentActivityL8yItems: state => state.currentActivityL8yItems,
    currentOutcomeActivityActivityL8yItems: state => state.currentOutcomeActivityActivityL8yItems,
    currentReversionOutcomeActivityActivityL8yItems: state => state.currentReversionOutcomeActivityActivityL8yItems,
    draftChangesOutcomeActivityActivityL8yItems: state => state.draftChangesOutcomeActivityActivityL8yItems,
  },
}
