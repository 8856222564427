import api from '@/services/api.js'
import router from '@/router/index.js'
import {
  SET_CLIENT,
  SET_RECENT_CURRICULUM_MAPS,
  SET_RECENT_LEARNING_PATHS,

  SET_CLIENT_LEARNING_PATHS,
  SET_CLIENT_MAPS,
  SET_CLIENT_THEMES,
  SET_CLIENT_LTI_LINKS,
  SET_CLIENT_USAGE,

  SET_CURRENT_CLIENT_UUID,
  SET_CLIENT_TAGS,
  SET_CLIENT_TAG_CATEGORIES,
  SET_TAGS_BY_CATEGORIES,
  SET_CLIENT_LTI_TOOLS,
  SET_CLIENT_DEPLOYMENTS,

  SET_CLIENT_LOADED,
  SET_CLIENT_LOADING,
  SET_DASHBOARD_LOADED,
  SET_LEARNING_PATHS_LOADED,
  SET_THEMES_LOADED,
  SET_USAGE_LOADED,
  SET_TAGS_LOADED,
  SET_RELOADED,
} from '@/store/mutation-types.js'
import deepClone from '@/functions/helpers/deepClone'

export const clients = {
  state: {
    /** @type {?Client} client */
    client: undefined,

    /** @type {LearningPath[]} recentLearningPaths */
    recentLearningPaths: [],

    /** @type {LearningPath[]} clientLearningPaths */
    clientLearningPaths: [],

    /** @type {Theme[]} clientThemes */
    clientThemes: [],

    /** @type {LtiLink[]} clientLtiLinks */
    clientLtiLinks: [],

    clientUsage: {},

    /** @type {Tag[]} clientTags */
    clientTags: [],

    /** @type {TagCategory[]} clientTagCategories */
    clientTagCategories: [],

    /** @type {TagCategory[]} tagsByCategories */
    tagsByCategories: [{
      uuid: undefined,
      name: 'Uncategorized',
      open: true,
      tags: [{
        uuid: undefined,
        name: 'Uncategorized',
        open: true,
      }],
    }],

    clientLtiTools: [],

    clientDeployments: [],

    /** @type {?UUIDv7} currentClientUuid */
    currentClientUuid: localStorage.getItem('currentClientUuid') || undefined,

    clientLoaded: false,

    dashboardLoaded: false,

    tagsLoaded: false,

    pathsLoaded: false,

    themesLoaded: false,

    usageLoaded: false,

    reloaded: false,
  },
  mutations: {
    [SET_CLIENT] (state, client) {
      state.client = client
    },

    [SET_RECENT_LEARNING_PATHS] (state, recentLearningPaths) {
      state.recentLearningPaths = recentLearningPaths
    },
    [SET_CLIENT_LEARNING_PATHS] (state, list) {
      state.clientLearningPaths = list
    },

    [SET_CLIENT_THEMES] (state, list) {
      state.clientThemes = list
    },
    [SET_CLIENT_LTI_LINKS] (state, list) {
      state.clientLtiLinks = list
    },
    [SET_CLIENT_USAGE] (state, obj) {
      state.clientUsage = obj
    },
    [SET_CLIENT_TAGS] (state, tags) {
      state.clientTags = tags
    },
    [SET_CLIENT_TAG_CATEGORIES] (state, tagCategories) {
      state.clientTagCategories = tagCategories
    },
    [SET_TAGS_BY_CATEGORIES] (state, tagsByCategories) {
      state.tagsByCategories = tagsByCategories
    },
    [SET_CLIENT_DEPLOYMENTS] (state, deployments) {
      state.clientDeployments = deployments
    },
    [SET_CLIENT_LTI_TOOLS] (state, ltiTools) {
      state.clientLtiTools = ltiTools
    },
    [SET_CLIENT_LOADED] (state) {
      state.clientLoaded = true
    },
    [SET_CLIENT_LOADING] (state) {
      state.clientLoaded = false
    },
    [SET_CURRENT_CLIENT_UUID] (state, currentClientUuid) {
      state.currentClientUuid = currentClientUuid
      localStorage.setItem('currentClientUuid', currentClientUuid)
    },
    [SET_DASHBOARD_LOADED] (state, bool) {
      state.dashboardLoaded = bool
    },

    [SET_LEARNING_PATHS_LOADED] (state, bool) {
      state.pathsLoaded = bool
    },
    [SET_THEMES_LOADED] (state, bool) {
      state.themesLoaded = bool
    },
    [SET_USAGE_LOADED] (state, bool) {
      state.usageLoaded = bool
    },
    [SET_TAGS_LOADED] (state, bool) {
      state.tagsLoaded = bool
    },
    [SET_RELOADED] (state, bool) {
      state.reloaded = bool
    },
  },
  actions: {
    setCurrentClientUuid ({ commit, dispatch }, clientUuid) {
      commit(SET_CURRENT_CLIENT_UUID, clientUuid)

      try {
        commit(SET_RELOADED, true)
        // Lazy load all client states.
        dispatch('getClientDashboard', clientUuid)

        dispatch('getClientMaps', clientUuid)

        dispatch('getClientLearningPaths', clientUuid)

        dispatch('getClientThemes', clientUuid)

        dispatch('getClientTagAndCategories', clientUuid)

        dispatch('getClient', clientUuid)
      } catch (error) {
        console.log(error)
      }
    },

    setIndexLoading ({ commit }) {
      commit(SET_RELOADED, false)
      commit(SET_DASHBOARD_LOADED, false)
      commit(SET_LEARNING_PATHS_LOADED, false)
      commit(SET_THEMES_LOADED, false)
      commit(SET_USAGE_LOADED, false)
      commit(SET_TAGS_LOADED, false)

      commit(SET_RECENT_LEARNING_PATHS, [])
      commit(SET_RECENT_CURRICULUM_MAPS, [])

      commit(SET_CLIENT_MAPS, [])
      commit(SET_CLIENT_LEARNING_PATHS, [])
      commit(SET_CLIENT_THEMES, [])

      commit(SET_CLIENT_USAGE, {})

      commit(SET_CLIENT_TAGS, [])
      commit(SET_CLIENT_TAG_CATEGORIES, [])
      commit(SET_TAGS_BY_CATEGORIES, [{
        uuid: undefined,
        name: 'Uncategorized',
        open: true,
        tags: [{
          uuid: undefined,
          name: 'Uncategorized',
          open: true,
        }],
      }])
    },

    async getClient ({ commit }, clientUuid) {
      const resp = await api.getClient(clientUuid)
      const client = resp.data.data.client
      commit(SET_CLIENT, client)
      commit(SET_CLIENT_LOADED)

      if (router.currentRoute.name === 'login') {
        await router.push({
          name: 'Dashboard',
          params: { clientUuid: clientUuid },
        })
      }

      return client
    },

    /** @return {Boolean} */
    async getClientDashboard ({ commit }, clientUuid) {
      const responseLearningPaths = await api.getClientLearningPathsRecent(clientUuid)
      const recentLearningPaths = responseLearningPaths.data.data

      const responseMaps = await api.getClientMapsRecent(clientUuid)
      const recentMaps = responseMaps.data.data

      commit(SET_RECENT_LEARNING_PATHS, recentLearningPaths)
      commit(SET_RECENT_CURRICULUM_MAPS, recentMaps)
      commit(SET_DASHBOARD_LOADED, true)

      return true
    },

    /** @return {Boolean} */
    async getClientLearningPaths ({ commit }, clientUuid) {
      const resp = await api.getClientLearningPaths(clientUuid)
      const list = resp.data.data
      commit(SET_CLIENT_LEARNING_PATHS, list)
      commit(SET_LEARNING_PATHS_LOADED, true)

      return true
    },

    /** @return {Boolean} */
    async getClientThemes ({ commit }, clientUuid) {
      const resp = await api.getClientThemes(clientUuid)
      const list = resp.data.data
      commit(SET_CLIENT_THEMES, list)
      commit(SET_THEMES_LOADED, true)

      return true
    },

    /** @return {Boolean} */
    async getClientUsage ({ commit }, clientUuid) {
      const resp = await api.getClientUsage(clientUuid)
      const obj = resp.data.data
      commit(SET_CLIENT_USAGE, obj)
      commit(SET_USAGE_LOADED, true)

      return true
    },

    /** @return {Boolean} */
    async getClientTagAndCategories ({ commit, state }, clientUuid) {
      const responseTags = await api.getClientTags(clientUuid)
      const responseTagCategories = await api.getClientTagCategories(clientUuid)

      const tags = responseTags.data.data.tags

      const tagCategories = responseTagCategories.data.data.tagCategories

      const stateTagCategories = getItems(state.tagsByCategories)

      const tagsByCategories = setOrUpdateFolderList(
        stateTagCategories,
        tagCategories,
        tags,
      )

      commit(SET_CLIENT_TAGS, tags)
      commit(SET_CLIENT_TAG_CATEGORIES, tagCategories)
      commit(SET_TAGS_BY_CATEGORIES, tagsByCategories)

      return true
    },

    /** @return {Tag[]} */
    async getClientTags ({ commit }, clientUuid) {
      const resp = await api.getClientTags(clientUuid)
      const tags = resp.data.data.tags
      commit(SET_CLIENT_TAGS, tags)

      return tags
    },

    async getDeployments ({ commit }, clientUuid) {
      const resp = await api.getDeployments(clientUuid)
      const deployments = resp.data.data.deployments
      commit(SET_CLIENT_DEPLOYMENTS, deployments)

      return deployments
    },

    async getClientLtiTools ({ commit }, clientUuid) {
      const resp = await api.getClientLtiTools(clientUuid)
      const ltiTools = resp.data.data
      commit(SET_CLIENT_LTI_TOOLS, ltiTools)
      return ltiTools
    },

    /** @return {TagCategory[]} */
    async getClientTagCategories ({ commit }, clientUuid) {
      const resp = await api.getClientTagCategories(clientUuid)
      const tagCategories = resp.data.data.tagCategories
      commit(SET_CLIENT_TAG_CATEGORIES, tagCategories)

      return tagCategories
    },

    closeAllFolders ({ commit, state }) {
      const tagCategories = getItems(state.tagsByCategories)
      const tagsByCategories = updateOpenStatus(tagCategories, 'all', null, false)
      commit(SET_TAGS_BY_CATEGORIES, tagsByCategories)
    },

    openAllFolders ({ commit, state }) {
      const tagCategories = getItems(state.tagsByCategories)
      const tagsByCategories = updateOpenStatus(tagCategories, 'all', null, true)
      commit(SET_TAGS_BY_CATEGORIES, tagsByCategories)
    },

    closeTagFolders ({ commit, state }, uuid) {
      const tagCategories = getItems(state.tagsByCategories)
      const tagsByCategories = updateOpenStatus(tagCategories, 'categoryTagFolders', uuid, false)
      commit(SET_TAGS_BY_CATEGORIES, tagsByCategories)
    },

    openTagFolders ({ commit, state }, uuid) {
      const tagCategories = getItems(state.tagsByCategories)
      const tagsByCategories = updateOpenStatus(tagCategories, 'categoryTagFolders', uuid, true)
      commit(SET_TAGS_BY_CATEGORIES, tagsByCategories)
    },

    closeCategoryFolder ({ commit, state }, uuid) {
      const tagCategories = getItems(state.tagsByCategories)
      const tagsByCategories = updateOpenStatus(tagCategories, 'category', uuid, false)
      commit(SET_TAGS_BY_CATEGORIES, tagsByCategories)
    },

    openCategoryFolder ({ commit, state }, uuid) {
      const tagCategories = getItems(state.tagsByCategories)
      const tagsByCategories = updateOpenStatus(tagCategories, 'category', uuid, true)
      commit(SET_TAGS_BY_CATEGORIES, tagsByCategories)
    },

    closeTagFolder ({ commit, state }, uuid) {
      const tagCategories = getItems(state.tagsByCategories)
      const tagsByCategories = updateOpenStatus(tagCategories, 'tag', uuid, false)
      commit(SET_TAGS_BY_CATEGORIES, tagsByCategories)
    },

    openTagFolder ({ commit, state }, uuid) {
      const tagCategories = getItems(state.tagsByCategories)
      const tagsByCategories = updateOpenStatus(tagCategories, 'tag', uuid, true)
      commit(SET_TAGS_BY_CATEGORIES, tagsByCategories)
    },

    /** @return {Client} */
    async refreshClient ({ commit, state }) {
      const resp = await api.getClient(state.currentClientUuid)
      const client = resp.data.data.client
      commit(SET_CLIENT, client)

      if (router.currentRoute.name === 'login') {
        await router.push({
          name: 'Dashboard',
          params: { clientUuid: state.currentClientUuid },
        })
      }

      return client
    },
  },

  getters: {
    /** @return {Boolean} */
    dashboardLoaded: state => state.dashboardLoaded,

    /** @return {LearningPath[]} */
    recentLearningPaths: state => state.recentLearningPaths,

    /** @return {Boolean} */
    reloaded: state => state.reloaded,

    /** @return {Client} */
    currentClient: state => state.client,

    /** @return {?UUIDv7} */
    currentClientUuid: state => state.currentClientUuid,

    /**
     * Current client available learning paths
     *
     * Originates from API:
     * \Application\EntityRepository\LearningPath::getClientLearningPathReport
     *
     * @param state
     * @returns {LearningPath[]}
     */
    currentClientAvailableLearningPaths: state => state.clientLearningPaths,

    /** @return {Tag[]} */
    currentClientTags: state => state.clientTags,

    /** @return {TagCategory[]} */
    currentClientTagCategories: state => state.clientTagCategories,

    /** @return {TagCategory[]} */
    tagsByCategories: state => state.tagsByCategories,

    /** @return {Boolean} */
    currentRoleCanEdit: (state, getters, rootState) => {
      // noinspection JSUnresolvedReference
      if (
        state.currentClientUuid !== undefined &&
        typeof rootState.auth.clientAssignments !== 'undefined'
      ) {
        if (typeof getters.isAdmin !== 'undefined' && getters.isAdmin) {
          return true
        }

        // noinspection JSUnresolvedReference
        return canClientUserPermissionEdit(
          state.currentClientUuid,
          rootState.auth.clientAssignments,
        )
      }

      return false
    },
  },
}

/** @return {TagCategory[]} */
function getItems (tagsByCategories) {
  return deepClone.call(tagsByCategories)
}

/** @return {Boolean|undefined} */
function canClientUserPermissionEdit (clientUuid, clientAssignments) {
  const clientAssignment = clientAssignments.find(
    aClientAssignment => aClientAssignment.client.uuid === clientUuid,
  )

  if (clientAssignment !== undefined) {
    return clientAssignment.role.toLowerCase() === 'administrator'
  }

  return undefined
}

/**
 * Update Open Status
 *
 * @param {TagCategory[]} tagCategories
 * @param openStatusType - This should probably be an enum.
 * @param {Boolean} status
 * @param {UUIDv7} uuid
 * @return {Tag|TagCategory|TagCategory[]}
 */
function updateOpenStatus (tagCategories, openStatusType, uuid, status) {
  tagCategories.forEach(tagCategory => {
    if (openStatusType === 'all') {
      tagCategory.open = status
      tagCategory.tags.forEach(tag => {
        if (openStatusType === 'all') {
          tag.open = status
        }

        return tag
      })
    } else if (
      openStatusType === 'category' ||
      openStatusType === 'categoryTagFolders'
    ) {
      if (tagCategory.uuid === uuid) {
        if (openStatusType === 'category') {
          tagCategory.open = status
        }

        tagCategory.tags.forEach(tag => {
          tag.open = status

          return tag
        })
      }
    } else if (openStatusType === 'tag') {
      tagCategory.tags.forEach(tag => {
        if (tag.uuid === uuid) {
          tag.open = status
        }

        return tag
      })
    }

    return tagCategory
  })

  return tagCategories
}

function updateCategoryTags (oldTagsList, categoryTags) {
  // Update the tag label in oldTagsList that exist in categoryTags.
  // Remove any tags from oldTagsList that do not exist in categoryTags.
  oldTagsList.forEach(oldTag => {
    const thisTag = categoryTags.find(
      categoryTag => oldTag.uuid === categoryTag.uuid,
    )

    if (typeof thisTag !== 'undefined') {
      // Update label
      return { uuid: oldTag.uuid, name: thisTag.label, open: oldTag.open }
    } else {
      // Set to undefined
      return undefined
    }
  })

  // Add any new tags in categoryTags that are not in oldTagsList.
  categoryTags.forEach(categoryTag => {
    if (!oldTagsList.some(tag => tag.uuid === categoryTag.uuid)) {
      const tag = {
        uuid: categoryTag.uuid,
        name: categoryTag.label,
        open: true,
      }
      oldTagsList.push(tag)
    }
  })

  // Filter out the undefined.
  oldTagsList.filter(Boolean)

  // Return the updated tags list.
  return oldTagsList
}

/**
 * @param {TagCategory[]} tagsByCategories
 * @param {TagCategory[]} tagCategories
 * @param {Tag[]} tags
 * @return {TagCategory[]|undefined}
 */
function setOrUpdateFolderList (tagsByCategories, tagCategories, tags) {
  // Find any tag that exist in undefined. Refresh name, but keep open status.
  // Remove any tags within the undefined tagsByCategories, but not in tags.
  const undefinedCategory = tagsByCategories.find(
    tagCategory => tagCategory.uuid === undefined,
  )

  if (undefinedCategory !== undefined) {
    undefinedCategory.tags.forEach(aTag => {
      const thisTag = tags.find(anotherTag => anotherTag.uuid === aTag.uuid)

      if (thisTag !== undefined) {
        // Update label.
        return { uuid: aTag.uuid, name: thisTag.label, open: aTag.open }
      } else {
        // Set to undefined.
        return undefined
      }
    })
  }

  // Add any tags in tags without category, but not yet in tagsByCategories.
  // Set as open.
  tags.forEach(aTag => {
    if (aTag.tagCategory === null) {
      if (!undefinedCategory.tags.some(tag => tag.uuid === aTag.uuid)) {
        /** @var {Tag} tag */
        const tag = {
          uuid: aTag.uuid,
          name: aTag.label,
          l8ySlideOutIsOpen: true,
        }
        undefinedCategory.tags.push(tag)
      }
    }
  })

  // Filter out undefined tags within the undefinedCategory.
  undefinedCategory.tags.filter(Boolean)

  tagsByCategories.forEach(tagCategory => {
    // Update the undefined category; it's always the ID === 0.
    if (tagCategory.uuid === undefined) {
      return undefinedCategory
    }

    const foundTagCategory = tagCategories.find(
      aTagCategory => aTagCategory.uuid === tagCategory.uuid,
    )

    if (foundTagCategory !== undefined) {
      // Update label.
      return {
        uuid: tagCategory.uuid,
        name: foundTagCategory.label,
        open: tagCategory.open,
        tags: updateCategoryTags(tagCategory.tags, foundTagCategory.tags),
      }
    } else {
      // Set to undefined.
      return undefined
    }
  })

  // Add any tagCategories that do not appear in tagsByCategories. Set as open.
  tagCategories.forEach(tagCategory => {
    if (!tagsByCategories.some(
      aTagCategory => aTagCategory.uuid === tagCategory.uuid,
    )) {
      /** @type {TagCategory} category */
      const category = {
        uuid: tagCategory.uuid,
        name: tagCategory.label,
        l8ySlideOutIsOpen: true,
        tags: updateCategoryTags([], tagCategory.tags),
      }

      tagsByCategories.push(category)
    }
  })

  // Filter out any undefined from tagsByCategories.
  tagsByCategories.filter(Boolean)

  return tagsByCategories
}
