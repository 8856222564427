<template>
  <component
    :is="layout"
    v-if="layout !== 'authenticated' || clientLoaded"
  >
    <!--//
      TODO: Double check if this is the optimal way to do this:
     https://stackoverflow.com/questions/66210222/vue-refresh-page-on-param-id-changes-for-the-same-page
     //-->
    <!--// This key allows the page to re-render when the route is the
     same but the param values have changed, as happens with e.g. Activity. //-->
    <router-view :key="$route.fullPath" />
  </component>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'App',

  computed: {
    ...mapState({
      clientLoaded: (state) => state.clients.clientLoaded,
    }),

    layout () {
      let layout = 'unauthenticated'

      if (this.$route.meta.requiresAuth === true) {
        if (typeof this.$route.meta.layout !== 'undefined') {
          layout = this.$route.meta.layout
        } else {
          layout = 'authenticated'
        }
      }

      return `${layout}-layout`
    },
  },
}
</script>
