/* eslint import/no-named-as-default-member: off */
import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from '@/store/modules/auth'
import { clients } from '@/store/modules/clients'
import { curriculumMap } from '@/store/modules/curriculumMap'
import { learningPath } from '@/store/modules/learningPath'
import { ltiLink } from '@/store/modules/ltiLink'
import { activity } from '@/store/modules/activity'
import { activityL8yItems } from '@/store/modules/activityL8yItems'
import { rubric } from '@/store/modules/rubric'
import { global } from '@/store/modules/global'
import VueKatex from 'vue-katex'

Vue.use(Vuex)
Vue.use(VueKatex)

// noinspection JSCheckFunctionSignatures
export default new Vuex.Store({
  modules: {
    activity,
    activityL8yItems,
    auth,
    clients,
    curriculumMap,
    global,
    learningPath,
    ltiLink,
    rubric,
  },
  strict: process.env.NODE_ENV !== 'production',
})
