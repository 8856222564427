import { apiClient, addAuthorizationHeader } from '@/services/api'

/**
 * Activity API Endpoints
 *
 * Excludes endpoints for rubrics, attempts, l8yItems, and assessmentL8yItems.
 */
export default {
  getActivity (activityUuid) {
    return addAuthorizationHeader(apiClient).get('/api/activity/' + activityUuid)
  },

  deleteActivity (activityUuid) {
    return addAuthorizationHeader(apiClient).delete('/api/activity/' + activityUuid)
  },

  createActivityAssessmentL8y (params) {
    return addAuthorizationHeader(apiClient).post('/api/activity/l8y', params)
  },

  /**
   * Create Activity Assessment
   *
   * AKA Learnosity v1/legacy
   *
   * @param params
   * @returns {*}
   */
  createActivityAssessment (params) {
    return addAuthorizationHeader(apiClient).post('/api/activity/assessment', params)
  },

  createActivityLti (params) {
    return addAuthorizationHeader(apiClient).post('/api/activity/lti', params)
  },

  createActivityLti1p3 (params) {
    return addAuthorizationHeader(apiClient).post('/api/activity/lti1p3', params)
  },

  createActivityQuiz (params) {
    return addAuthorizationHeader(apiClient).post('/api/activity/quiz', params)
  },

  createActivitySurvey (params) {
    return addAuthorizationHeader(apiClient).post('/api/activity/survey', params)
  },

  createActivityMedia (params) {
    return addAuthorizationHeader(apiClient).post('/api/activity/media', params)
  },

  createActivityVideo (params) {
    return addAuthorizationHeader(apiClient).post('/api/activity/video', params)
  },

  createActivityFile (params) {
    return addAuthorizationHeader(apiClient).post('/api/activity/file-upload', params)
  },

  createActivityDiscussion (params) {
    return addAuthorizationHeader(apiClient).post('/api/activity/discussion', params)
  },

  updateActivityLti (activityUuid, params) {
    return addAuthorizationHeader(apiClient).put('/api/activity/lti/' + activityUuid, params)
  },

  updateActivityLti1p3 (activityUuid, params) {
    return addAuthorizationHeader(apiClient).put('/api/activity/lti1p3/' + activityUuid, params)
  },

  /**
   * Update Activity Assessment
   *
   * AKA Learnosity v1/legacy
   *
   * @param {UUIDv7} activityUuid
   * @param params
   * @returns {*}
   */
  updateActivityAssessment (activityUuid, params) {
    return addAuthorizationHeader(apiClient).put('/api/activity/assessment/' + activityUuid, params)
  },

  /**
   * Update Activity L8y
   *
   * AKA v2 Learnosity / "Learnosity embed"
   *
   * @param {UUIDv7} activityUuid
   * @param params
   * @returns {*}
   */
  updateActivityL8y (activityUuid, params) {
    return addAuthorizationHeader(apiClient).put(
      '/api/activity/l8y/' + activityUuid,
      params,
      { validateStatus: null },
    )
  },

  updateActivityQuiz (activityUuid, params) {
    return addAuthorizationHeader(apiClient).put('/api/activity/quiz/' + activityUuid, params)
  },

  updateActivitySurvey (activityUuid, params) {
    return addAuthorizationHeader(apiClient).put('/api/activity/survey/' + activityUuid, params)
  },

  updateActivityMedia (activityUuid, params) {
    return addAuthorizationHeader(apiClient).put('/api/activity/media/' + activityUuid, params)
  },

  updateActivityVideo (activityUuid, params) {
    return addAuthorizationHeader(apiClient).put('/api/activity/video/' + activityUuid, params)
  },

  updateActivityFile (activityUuid, params) {
    return addAuthorizationHeader(apiClient).put('/api/activity/file-upload/' + activityUuid, params)
  },

  updateActivityDiscussion (activityUuid, params) {
    return addAuthorizationHeader(apiClient).put('/api/activity/discussion/' + activityUuid, params)
  },

  deleteActivityFile (activityUuid, fileUuid) {
    return addAuthorizationHeader(apiClient).delete(
      `/api/activity/file-upload/${activityUuid}/remove-file/${fileUuid}`,
    )
  },
}
