import { castIntegerRouteParams } from '../index'

/**
 * Activity Routes
 */

/** @var {RouteName} ROUTE_LEARNING_PATH_PAGES_ACTIVITY */
export const ROUTE_LEARNING_PATH_PAGES_ACTIVITY = 'learning-path-pages-activity'

/** @var {RouteName} RUBRIC_MODAL_MODE_ROUTE_EDIT */
export const RUBRIC_MODAL_MODE_ROUTE_EDIT = 'learning-path-pages-activity-rubric-edit'

/** @var {RouteName} RUBRIC_MODAL_MODE_ROUTE_VIEW */
export const RUBRIC_MODAL_MODE_ROUTE_VIEW = 'learning-path-pages-activity-rubric-view'

/** @var {RouteName} RUBRIC_MODAL_MODE_ROUTE_MAP_OUTCOMES */
export const RUBRIC_MODAL_MODE_ROUTE_MAP_OUTCOMES = 'learning-path-pages-activity-rubric-map-outcomes'

/** @var {RouteName} ACTIVITY_L8Y_ITEMS_MODAL_MODE_ROUTE_MAP_OUTCOMES */
export const ACTIVITY_L8Y_ITEMS_MODAL_MODE_ROUTE_MAP_OUTCOMES = 'learning-path-pages-activity-activity-l8y-items-map-outcomes'

/** @var {RouteName} ACTIVITY_IMPORT_QTI */
export const ACTIVITY_IMPORT_QTI = 'learning-path-pages-activity-import-qti'

/** @var {RouteName[]} RUBRIC_MODAL_ROUTES */
export const RUBRIC_MODAL_ROUTES = [
  RUBRIC_MODAL_MODE_ROUTE_EDIT,
  RUBRIC_MODAL_MODE_ROUTE_VIEW,
  RUBRIC_MODAL_MODE_ROUTE_MAP_OUTCOMES,
]

/** @var {RouteName[]} ACTIVITY_L8Y_ITEMS_MODAL_ROUTES */
export const ACTIVITY_L8Y_ITEMS_MODAL_ROUTES = [
  ACTIVITY_L8Y_ITEMS_MODAL_MODE_ROUTE_MAP_OUTCOMES,
]

/** @return {RouteRecordSingleView[]} */
export default [
  /** View Activity */
  {
    path: '/client/:clientUuid/learning-path/:learningPathUuid/module/:moduleId/page/:pageId/activity/:activityUuid',
    name: ROUTE_LEARNING_PATH_PAGES_ACTIVITY,
    meta: { requiresAuth: true },
    component: () => import('../../views/path/pages/Activity.vue'),
    children: [
      {
        path: 'rubric',
        name: 'learning-path-pages-activity-rubric',
        meta: { requiresAuth: true },
        component: () => import('@/components/rubric/RubricModal.vue'),
        children: [
          {
            path: 'view',
            name: RUBRIC_MODAL_MODE_ROUTE_VIEW,
            meta: { requiresAuth: true },
            component: () => import('@/components/rubric/RubricModal.vue'),
          },
          {
            path: 'edit',
            name: RUBRIC_MODAL_MODE_ROUTE_EDIT,
            meta: { requiresAuth: true },
            component: () => import('@/components/rubric/RubricModal.vue'),
          },
          {
            path: 'map-outcomes',
            name: RUBRIC_MODAL_MODE_ROUTE_MAP_OUTCOMES,
            meta: { requiresAuth: true },
            component: () => import('@/components/rubric/RubricModal.vue'),
          },
        ],
      },
      {
        path: 'activity-l8y-items',
        name: 'learning-path-pages-activity-activity-l8y-items',
        meta: { requiresAuth: true },
        component: () => import('@/components/activity/modals/ActivityL8yItemsModal.vue'),
        children: [
          {
            path: 'map-outcomes',
            name: ACTIVITY_L8Y_ITEMS_MODAL_MODE_ROUTE_MAP_OUTCOMES,
            meta: { requiresAuth: true },
            component: () => import('@/components/activity/modals/ActivityL8yItemsOutcomes.vue'),
          },
        ],
      },
      {
        path: 'import-qti',
        name: ACTIVITY_IMPORT_QTI,
        meta: { requiresAuth: true },
        component: () => import('@/components/activity/modals/ImportQti.vue'),
      },
    ],
  },

  /** Create Activity */
  {
    path: '/client/:clientUuid/learning-path/:learningPathUuid/module/:moduleId/page/:pageId/create-activity',
    name: 'learning-path-pages-activity-create',
    meta: { requiresAuth: true },
    component: () => import('../../views/path/pages/ActivityCreate.vue'),
    props: castIntegerRouteParams,
  },
]
