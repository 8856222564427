import { apiClient, addAuthorizationHeader } from '@/services/api'

export default {
  getOutcome (outcomeUuid) {
    return addAuthorizationHeader(apiClient).get('/api/outcome/' + outcomeUuid)
  },

  /**
   * Create Outcome
   *
   * The 'validateStatus: null' allows callers of this endpoint to handle errors
   * like 500s on their own, e.g. with a custom modal, without forwarding to the
   * universal/global glitch page.
   *
   * @param {OutcomeScalars} outcome
   * @returns {Promise<*>}
   */
  async createOutcome (outcome) {
    return addAuthorizationHeader(apiClient).post(
      '/api/outcome',
      outcome,
      { validateStatus: null },
    )
  },

  async updateOutcome (outcomeUuid, params) {
    return addAuthorizationHeader(apiClient).put('/api/outcome/' + outcomeUuid, params)
  },

  async deleteOutcome (outcomeUuid) {
    return addAuthorizationHeader(apiClient).delete('/api/outcome/' + outcomeUuid)
  },

  async setOutcomeChildren (uuid, params) {
    return addAuthorizationHeader(apiClient).put(`/api/outcome/${uuid}/set-children`, params)
  },
}
