import api from '@/services/api'

export default {
  call (id) {
    if (id !== 'new') {
      return new Promise((resolve, reject) => {
        api.getMap(id)
          .then(resp => {
            resolve(resp)
          })
          .catch(error => {
            reject(error)
          })
      })
    } else {
      return {
        map: {
          title: '',
          outcomes: [
            {
              level: 1,
              outcomes: [],
            },
            {
              level: 2,
              outcomes: [],
            },
            {
              level: 3,
              outcomes: [],
            },
          ],
          structure: [],
          lineage: [],
          outcomeLevels: [
            {
              name: 'Program Outcomes',
              depth: 1,
            },
            {
              name: 'Course Outcomes',
              depth: 2,
            },
            {
              name: 'Learning Outcomes',
              depth: 3,
            },
          ],
          achievementLevels: [
            { id: 1, text: 'Excellent', points: 100 },
            { id: 2, text: 'Very Good', points: 90 },
            { id: 3, text: 'Satisfactory', points: 75 },
            { id: 4, text: 'Needs Work', points: 50 },
            { id: 5, text: 'Unsatisfactory', points: 0 },
          ],
          version: '1',
        },
      }
    }
  },
}
